<script setup lang="ts">
import { reactive, watchEffect } from 'vue';
import { BG_POSITION_OPTIONS } from './settings';
import type { BgPosition } from './types';
import Dot from './Dot.vue';

type Props = {
  id: string;
  value: BgPosition;
  controlClass?: string;
};

type Emit = {
  (e: 'controlChange', controlId: string, value: BgPosition): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emit>();
const val = reactive<BgPosition>({ x: 0, y: 0 });

const onChange = (position: BgPosition) => {
  setVal(position);
  emitChange();
};

const setVal = (position: BgPosition) => {
  val.x = position.x;
  val.y = position.y;
};

const emitChange = () => {
  emit('controlChange', props.id, val);
};

const isActive = (position: BgPosition) => {
  return val.x === position.x && val.y === position.y;
};

watchEffect(() => {
  setVal(props.value);
});
</script>
<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="flex" :class="controlClass">
      <div class="bg-dark-400 rounded-medium grid aspect-square w-[88px] flex-none grid-cols-3 grid-rows-3 gap-4 p-4">
        <div
          v-for="(item, index) in BG_POSITION_OPTIONS"
          :key="index"
          class="items-center justify-center"
          @click="onChange(item.value)">
          <Dot :selected="isActive(item.value)" />
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>
