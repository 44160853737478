<script setup lang="ts">
import InputUnit from '../InputUnit.vue';

type Props = {
  resetKey?: number | string;
  vertical?: string;
  horizontal?: string;
};

defineProps<Props>();

const emit = defineEmits<{
  (e: 'change', name: string, value: any): void;
  (e: 'on-change', name: string, value: any): void;
}>();
const change = (name: string, value: any) => {
  emit('change', name, value);
};
const onControlChange = (name: string, value: any) => {
  emit('on-change', name, value);
};
</script>
<template>
  <div class="flex flex-col gap-16">
    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-dark-low whitespace-nowrap">Top & Bottom</label>
      <div class="w-[140px]">
        <InputUnit
          id="vertical"
          :key="resetKey"
          input-class="bg-dark-300"
          :min="0"
          :value="vertical"
          :units="['px']"
          :control-change="change"
          :empty-on-clear="!vertical"
          @control-on-change="onControlChange" />
      </div>
    </div>
    <div class="flex items-center justify-between gap-16">
      <label class="text-12 text-dark-low whitespace-nowrap">Left & Right</label>
      <div class="w-[140px]">
        <InputUnit
          id="horizontal"
          :key="resetKey"
          input-class="bg-dark-300"
          :min="0"
          :value="horizontal"
          :units="['px']"
          :control-change="change"
          :empty-on-clear="!horizontal"
          @control-on-change="onControlChange" />
      </div>
    </div>
  </div>
</template>
