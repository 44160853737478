import { sentryCaptureException } from '../../common/use-cases/sentry';

export const validateValueSetting = (data: { controlId: string; newValue: any }) => {
  if (data.newValue) {
    if (typeof data.newValue == 'string') {
      if (data.newValue.includes('NaN')) {
        sentryCaptureException('validateValueSetting', 'The value is not correct', data);
      } else if (data.newValue.includes('undefined')) {
        sentryCaptureException('validateValueSetting', 'The value is not correct', data);
      }
    } else if (isNaN(data.newValue)) {
      sentryCaptureException('validateValueSetting', 'The value is not correct', data);
    }
  }
};
