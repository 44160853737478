<script lang="ts" setup>
import { ref } from 'vue';

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'uploadImage', file: File): void;
}>();

const props = withDefaults(
  defineProps<{
    images?: any[];
    maximumSize?: number;
    allowedFiles?: string[];
  }>(),
  {
    allowedFiles: () => ['image/avif', 'image/jpeg', 'image/tiff', 'image/bmp', 'image/gif', 'image/png', 'image/webp'],
    maximumSize: 2 * 1024 * 1024, // 2MB
  },
);

const deletedVisible = ref(false);

const closeGallery = () => {
  emit('close');
};

const handleChangeFile = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;
  if (!props.allowedFiles?.includes(file.type) || file.size > props.maximumSize) {
    return;
  }
  postUploadImage(file);
  close();
};

const postUploadImage = async (_urlImage: File) => {
  emit('uploadImage', _urlImage);
};

const close = () => {
  emit('close');
};
</script>

<template>
  <g-modal :is-open="true" :show-btn-close="true" @cancel="closeGallery" @close="closeGallery">
    <template #title>Choose from galleries</template>
    <template #default>
      <div class="w-[700px]">
        <div v-if="!deletedVisible" class="grid grid-cols-3 gap-20 px-20">
          <div class="rounded-medium relative flex items-center justify-center border border-solid border-gray-300">
            <input
              class="absolute h-full w-full cursor-pointer opacity-0"
              type="file"
              accept="image/*"
              @change.stop="handleChangeFile" />
            <span class="inline-flex">
              <g-base-icon name="plus" width="65" height="65" />
            </span>
          </div>
          <div
            v-for="image in images"
            :key="image?.node?.id"
            class="rounded-medium flex items-center justify-center overflow-hidden border border-solid border-gray-300">
            <img
              :src="
                image?.node?.filePath + '/-/format/auto/-/preview/200x200/-/quality/lighter/' + image?.node?.fileName
              "
              alt="image" />
          </div>
        </div>

        <div></div>
      </div>
    </template>
  </g-modal>
</template>
