<script lang="ts" setup>
import JustifyContentItem from './JustifyContentItem.vue';

const props = defineProps<{
  value?: string;
}>();
const emit = defineEmits<{
  (e: 'select', value?: string): void;
}>();

const items = [
  {
    value: 'flex-start',
    label: 'Top',
    className: 'justify-start',
  },
  {
    value: 'flex-end',
    label: 'Bottom',
    className: 'justify-end',
  },
  {
    value: 'center',
    label: 'Center',
    className: 'justify-center',
  },
  {
    value: 'space-between',
    label: 'Between',
    className: 'justify-between',
  },
];

const onSelect = (val: string) => {
  if (props.value === val) {
    emit('select', undefined);
  } else {
    emit('select', val);
  }
};
</script>

<template>
  <div class="flex gap-8">
    <JustifyContentItem
      v-for="item in items"
      :key="item.value"
      :selected="value === item.value"
      :class="item.className"
      :aria-label="item.label"
      @click="onSelect(item.value)" />
  </div>
</template>
