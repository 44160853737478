<template>
  <button
    type="button"
    role="button"
    aria-pressed="false"
    class="gt_text_format_italic item"
    @click="setFormat('.gt_text_format_italic')">
    <svg focusable="false" width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="fr-svg">
      <path d="M11.76,9h2l-2.2,10h-2Zm1.68-4a1,1,0,1,0,1,1,1,1,0,0,0-1-1Z" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'FormatItalic',
  emits: ['statusFormatText'],
  methods: {
    setFormat(classButton) {
      const $parrantButton = event.target.closest(classButton);
      this.$emit('statusFormatText', {
        type: 'italic',
        classButton: $parrantButton,
      });
    },
  },
};
</script>
