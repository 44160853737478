<template>
  <button
    type="button"
    role="button"
    aria-pressed="false"
    class="gt_text_format_bold item"
    @click="setFormat('.gt_text_format_bold')">
    <svg class="fr-svg" focusable="false" width="22" height="22" viewBox="0 0 24 24">
      <path
        d="M15.25,11.8h0A3.68,3.68,0,0,0,17,9a3.93,3.93,0,0,0-3.86-4H6.65V19h7a3.74,3.74,0,0,0,3.7-3.78V15.1A3.64,3.64,0,0,0,15.25,11.8ZM8.65,7h4.2a2.09,2.09,0,0,1,2,1.3,2.09,2.09,0,0,1-1.37,2.61,2.23,2.23,0,0,1-.63.09H8.65Zm4.6,10H8.65V13h4.6a2.09,2.09,0,0,1,2,1.3,2.09,2.09,0,0,1-1.37,2.61A2.23,2.23,0,0,1,13.25,17Z" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'FormatBold',
  emits: ['statusFormatText'],
  methods: {
    setFormat(classButton) {
      const $parrantButton = event.target.closest(classButton);
      this.$emit('statusFormatText', {
        type: 'bold',
        classButton: $parrantButton,
      });
    },
  },
};
</script>
