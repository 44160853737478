<script lang="ts" setup>
import Dot from './Dot.vue';

const props = defineProps<{
  id: string;
  value?: number | string;
}>();

const values = ['top-left', 'top', 'top-right', 'left', 'center', 'right', 'bottom-left', 'bottom', 'bottom-right'];

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: string): void;
}>();

const change = (value?: string) => {
  emit('controlChange', props.id, value);
};
</script>

<template>
  <div>
    <slot name="label"></slot>
    <div
      class="bg-dark-400 border-dark-200 rounded-medium mx-auto grid aspect-square w-[88px] grid-cols-3 grid-rows-3 place-content-center place-items-center border mr-0">
      <Dot v-for="item in values" :key="item" :value="item" :selected="item == value" @change="change" />
    </div>
    <slot name="info"></slot>
    <slot name="modal"></slot>
  </div>
</template>
