<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import type { Product, ProductOption } from '../type/common';
import ProductModal from './modal/ProductModal.vue';
import Select from './Select.vue';

type ProductSetting = {
  productId?: string;
  initialVariantId?: string;
  initialVariantBaseId?: string;
  productHandle?: string;
  productStatus?: string;
  isUpdatedProduct?: boolean;
};

type PickedProductType = {
  productId?: string;
  productHandle?: string;
  productStatus?: 'dynamic' | 'static';
  productTitle?: string;
  productImage?: string;
} | null;

type Props = {
  id?: string;
  value?: ProductSetting;
  productDisplay?: {
    title?: string;
    image?: string;
    id?: string;
  };
  isLoading?: boolean;
  options?: ProductOption[];
  connectStatus?: boolean;
  productList?: Product[];
  syncPercent?: number;
  pageType?: string;
  pickedDynamicProduct?: PickedProductType;
  pickedStaticProduct?: PickedProductType;
};

const props = withDefaults(defineProps<Props>(), {
  connectStatus: false,
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
  (e: 'controlOnSearch', controlId?: string | number, value?: any): void;
  (e: 'controlRefresh'): void;
  (e: 'connect-shopify'): void;
  (e: 'showMore', action?: 'fetch'): void;
  (e: 'openAssignModal'): void;
  (e: 'setPickedStatic', pickedStaticProduct: PickedProductType): void;
}>();

const productListVisibility = ref<boolean>(false);

const DYNAMIC_PRODUCT_STATUS = 'dynamic';
const STATIC_PRODUCT_STATUS = 'static';

const valueDefaults = computed(() => {
  return (
    props.value ?? {
      productId: 'latest',
      initialVariantId: 'latest',
      initialVariantBaseId: null,
      productHandle: 'latest',
      productStatus: STATIC_PRODUCT_STATUS,
    }
  );
});

const closeModal = () => {
  productListVisibility.value = false;
};

const openModal = () => {
  if (props.value?.productStatus == DYNAMIC_PRODUCT_STATUS) {
    emit('openAssignModal');
  } else {
    productListVisibility.value = true;
  }
};

const setValueSelected = (productValue?: string) => {
  if (productValue) {
    if (productValue !== valueDefaults.value?.productId) {
      emit('setPickedStatic', { productId: productValue, productStatus: STATIC_PRODUCT_STATUS });
      const product = props.productList?.find((item) => item.id === productValue);
      emit('controlChange', props.id, {
        productId: productValue,
        initialVariantId: valueDefaults.value.initialVariantId,
        initialVariantBaseId: valueDefaults.value.initialVariantBaseId,
        productHandle: product?.handle,
        productStatus: props?.value?.productStatus,
      });
    }
  }
};

const onSearch = (value?: string) => {
  emit('controlOnSearch', props.id, value);
};

const selectProductOptions = [
  { label: 'Display by assigned product', value: DYNAMIC_PRODUCT_STATUS },
  { label: 'Select manually', value: STATIC_PRODUCT_STATUS },
];

const onChangeProductType = (name: string, newType: any) => {
  let productId, productHandle;

  if (newType == DYNAMIC_PRODUCT_STATUS) {
    const pickedDynamicProduct = props?.pickedDynamicProduct;
    if (pickedDynamicProduct && pickedDynamicProduct.productId) {
      productId = pickedDynamicProduct.productId;
      productHandle = pickedDynamicProduct.productHandle;
    }
  } else {
    const pickedStaticProduct = props?.pickedStaticProduct;
    if (!pickedStaticProduct || !pickedStaticProduct.productId) {
      emit('setPickedStatic', { productId: 'latest', productStatus: STATIC_PRODUCT_STATUS });
    }
    if (pickedStaticProduct && pickedStaticProduct.productId) {
      productId = pickedStaticProduct.productId;
      productHandle = pickedStaticProduct.productHandle;
    }
  }
  emit('controlChange', props.id, {
    productId: productId ?? 'latest',
    initialVariantId: valueDefaults.value.initialVariantId,
    initialVariantBaseId: valueDefaults.value.initialVariantBaseId,
    productHandle: productHandle,
    productStatus: newType,
  });
};

const convertProductDisplay = computed(() => {
  if (props.value?.productStatus == DYNAMIC_PRODUCT_STATUS) {
    if (
      !props.pickedDynamicProduct ||
      !props.pickedDynamicProduct.productId ||
      props.pickedDynamicProduct.productId === 'latest'
    ) {
      return {
        title: 'No product assigned',
      };
    } else {
      return {
        id: props.pickedDynamicProduct.productId,
        title: props.pickedDynamicProduct.productTitle,
        image: props.pickedDynamicProduct.productImage,
      };
    }
  } else {
    return props.productDisplay;
  }
});

onMounted(() => {
  if (props?.value?.isUpdatedProduct) {
    emit('controlChange', props.id, {
      productId: props.value?.productId,
      initialVariantId: valueDefaults.value.initialVariantId,
      initialVariantBaseId: valueDefaults.value.initialVariantBaseId,
      productHandle: props.value?.productHandle,
      productStatus: props.value?.productStatus,
    });
  }
});
</script>
<template>
  <div>
    <Select
      v-if="props.pageType == 'GP_PRODUCT'"
      id="select-product"
      class="pb-8"
      :options="selectProductOptions"
      :floating="false"
      dropdown-classes="w-full"
      :value="value?.productStatus ?? STATIC_PRODUCT_STATUS"
      @control-change="onChangeProductType"></Select>
    <div class="mt-8 flex flex-col gap-8">
      <div
        v-if="isLoading && !convertProductDisplay"
        class="bg-dark-400 rounded-medium mx-[-7px] flex animate-pulse items-center gap-8 p-8">
        <div class="rounded-medium bg-dark-200 aspect-square w-32 shrink-0"></div>
        <div class="bg-dark-200 h-10 flex-1 shrink-0 rounded-full"></div>
      </div>
      <div v-else class="rounded-medium flex gap-[11px] p-8 pt-0">
        <img
          v-if="convertProductDisplay?.image"
          class="rounded-medium aspect-square h-32 w-32 shrink-0 object-cover"
          :src="convertProductDisplay?.image"
          alt="product feature img" />
        <div v-else class="rounded-medium aspect-square h-32 w-32 shrink-0 overflow-hidden">
          <svg
            v-if="
              value?.productStatus == DYNAMIC_PRODUCT_STATUS &&
              (!pickedDynamicProduct || !pickedDynamicProduct.productId)
            "
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 12.5C21 12.8978 20.842 13.2794 20.5607 13.5607C20.2794 13.842 19.8978 14 19.5 14C19.1026 13.997 18.7218 13.8402 18.4375 13.5625C18.2978 13.4233 18.1871 13.2576 18.112 13.0752C18.0369 12.8928 17.9988 12.6973 18 12.5C18 12.1022 18.158 11.7206 18.4393 11.4393C18.7206 11.158 19.1022 11 19.5 11C19.8978 11 20.2794 11.158 20.5607 11.4393C20.842 11.7206 21 12.1022 21 12.5ZM29 7V25C29 25.5304 28.7893 26.0391 28.4142 26.4142C28.0391 26.7893 27.5304 27 27 27H5C4.46957 27 3.96086 26.7893 3.58579 26.4142C3.21071 26.0391 3 25.5304 3 25V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H27C27.5304 5 28.0391 5.21071 28.4142 5.58579C28.7893 5.96086 29 6.46957 29 7ZM27 20.5875V7H5V18.5875L9.5875 14C9.96402 13.629 10.4714 13.421 11 13.421C11.5286 13.421 12.036 13.629 12.4125 14L18 19.5875L20.5875 17C20.964 16.629 21.4714 16.421 22 16.421C22.5286 16.421 23.036 16.629 23.4125 17L27 20.5875Z"
              fill="#757575" />
          </svg>
          <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_280_39360)">
              <rect width="32" height="32" fill="#EEEEEE" />
              <path
                d="M18.8125 14.0312C18.8125 14.255 18.7236 14.4696 18.5654 14.6279C18.4071 14.7861 18.1925 14.875 17.9688 14.875C17.7452 14.8733 17.531 14.7851 17.3711 14.6289C17.2925 14.5506 17.2303 14.4574 17.188 14.3548C17.1458 14.2522 17.1243 14.1422 17.125 14.0312C17.125 13.8075 17.2139 13.5929 17.3721 13.4346C17.5304 13.2764 17.745 13.1875 17.9688 13.1875C18.1925 13.1875 18.4071 13.2764 18.5654 13.4346C18.7236 13.5929 18.8125 13.8075 18.8125 14.0312ZM23.3125 10.9375V21.0625C23.3125 21.3609 23.194 21.647 22.983 21.858C22.772 22.069 22.4859 22.1875 22.1875 22.1875H9.8125C9.51413 22.1875 9.22798 22.069 9.017 21.858C8.80603 21.647 8.6875 21.3609 8.6875 21.0625V10.9375C8.6875 10.6391 8.80603 10.353 9.017 10.142C9.22798 9.93103 9.51413 9.8125 9.8125 9.8125H22.1875C22.4859 9.8125 22.772 9.93103 22.983 10.142C23.194 10.353 23.3125 10.6391 23.3125 10.9375ZM22.1875 18.5805V10.9375H9.8125V17.4555L12.393 14.875C12.6048 14.6663 12.8902 14.5493 13.1875 14.5493C13.4848 14.5493 13.7702 14.6663 13.982 14.875L17.125 18.018L18.5805 16.5625C18.7923 16.3538 19.0777 16.2368 19.375 16.2368C19.6723 16.2368 19.9577 16.3538 20.1695 16.5625L22.1875 18.5805Z"
                fill="#494949" />
            </g>
            <defs>
              <clipPath id="clip0_280_39360">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <p class="text-dark-high font-regular text-12 line-clamp-2 self-center leading-[14px]">
          {{ convertProductDisplay?.title }}
        </p>
      </div>
      <div class="select-product-btn flex">
        <g-button
          type="tertiary"
          button-classes="flex h-[36px] w-full items-center justify-center gap-8 bg-dark-300"
          @click="openModal">
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5 1.35181C10.8452 1.35181 11.125 1.63163 11.125 1.97681V3.15303C14.4232 3.45024 17.0498 6.07679 17.347 9.375H18.5234C18.8686 9.375 19.1484 9.65482 19.1484 10C19.1484 10.3452 18.8686 10.625 18.5234 10.625H17.347C17.0498 13.9232 14.4232 16.5498 11.125 16.847V18.0234C11.125 18.3686 10.8452 18.6484 10.5 18.6484C10.1548 18.6484 9.875 18.3686 9.875 18.0234V16.847C6.57679 16.5498 3.95024 13.9232 3.65302 10.625H2.47673C2.13155 10.625 1.85173 10.3452 1.85173 10C1.85173 9.65482 2.13155 9.375 2.47673 9.375H3.65302C3.95024 6.07679 6.57679 3.45024 9.875 3.15303V1.97681C9.875 1.63163 10.1548 1.35181 10.5 1.35181ZM4.90933 10.625H7.47673C7.82191 10.625 8.10173 10.3452 8.10173 10C8.10173 9.65482 7.82191 9.375 7.47673 9.375H4.90933C5.19755 6.76774 7.26774 4.69755 9.875 4.40933V6.97681C9.875 7.32198 10.1548 7.60181 10.5 7.60181C10.8452 7.60181 11.125 7.32198 11.125 6.97681V4.40933C13.7323 4.69755 15.8025 6.76774 16.0907 9.375H13.5234C13.1783 9.375 12.8984 9.65482 12.8984 10C12.8984 10.3452 13.1783 10.625 13.5234 10.625H16.0907C15.8025 13.2323 13.7323 15.3025 11.125 15.5907V13.0234C11.125 12.6782 10.8452 12.3984 10.5 12.3984C10.1548 12.3984 9.875 12.6782 9.875 13.0234V15.5907C7.26774 15.3025 5.19755 13.2323 4.90933 10.625Z"
              fill="white" />
          </svg>
          <p class="text-12 text-white">
            {{
              value?.productStatus == DYNAMIC_PRODUCT_STATUS
                ? pickedDynamicProduct
                  ? 'Change preview'
                  : 'Assign product'
                : 'Pick product'
            }}
          </p>
        </g-button>
      </div>
    </div>
    <ProductModal
      :value="productDisplay"
      :list="productList"
      :type="'Product'"
      :open="productListVisibility"
      :is-loading="isLoading"
      :sync-percent="syncPercent"
      :page-type="pageType"
      @show-more="emit('showMore', 'fetch')"
      @refresh="emit('controlRefresh')"
      @close="closeModal"
      @set-value-selected="setValueSelected"
      @on-search="onSearch" />
  </div>
</template>
