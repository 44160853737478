<template>
  <div class="gt_text_insert-link item">
    <button
      type="button"
      role="button"
      aria-pressed="false"
      class="gt_text_insert_link_scope bg-dark-300 border-dark-200 flex items-center justify-center border hover:bg-[#515151]"
      @click="showPopup()">
      <svg
        fill="currentColor"
        class="fr-svg"
        width="22"
        height="22"
        focusable="false"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11,17H7A5,5,0,0,1,7,7h4V9H7a3,3,0,0,0,0,6h4ZM17,7H13V9h4a3,3,0,0,1,0,6H13v2h4A5,5,0,0,0,17,7Zm-1,4H8v2h8Z" />
      </svg>
    </button>

    <popupInsertLink
      ref="popupInsertLinkComponent"
      :status-display="statusShowPopup"
      :val-text-insert-link="textLink"
      :link="link"
      :is-open-new-tab="openNewTab"
      class-button=".gt_text_insert_link_scope"
      @status-display-popup-insert-link="statusDisplay"
      @submit-form-insert-link="submitForm" />
  </div>
</template>
<script>
import popupInsertLink from './popup/popupInsertLink.vue';
export default {
  name: 'InsertLink',
  components: {
    popupInsertLink,
  },
  emits: ['statusFormatText'],
  data() {
    return {
      statusShowPopup: false,
      textLink: '',
      link: '',
      openNewTab: false,
    };
  },
  methods: {
    submitForm(data) {
      data.classButton = document.getElementsByClassName('gt_text_insert_link_scope')[0];
      this.$emit('statusFormatText', data);
    },
    statusDisplay(val) {
      this.statusShowPopup = val;
    },
    showPopup() {
      if (this.statusShowPopup == true) {
        this.$refs.popupInsertLinkComponent.setStatus(false);
      } else {
        const selection = window.getSelection();
        if (selection.type == 'Range') {
          const container = document.createElement('div');
          container.appendChild(selection.getRangeAt(0).cloneContents());
          this.textLink = container.innerHTML;
          const parentSelected = selection.focusNode.parentNode;
          if (parentSelected.closest('#gt_text-editor') && parentSelected.nodeName === 'A') {
            this.link = parentSelected.getAttributeNode('href').value;
            if (parentSelected.getAttributeNode('target')) {
              this.openNewTab = parentSelected.getAttributeNode('target').value === '_blank';
            }
          } else {
            this.link = '';
          }
        }
        if (selection.type == 'Caret') {
          this.textLink = '';
        }
        this.$refs.popupInsertLinkComponent.setStatus(true);
      }
    },
  },
};
</script>
<style lang="postcss" scoped>
.gt_text_insert_link_scope {
  cursor: pointer;
  width: theme('spacing.32');
  height: theme('spacing.32');
  border-radius: theme('borderRadius.medium');
}
</style>
