<script lang="ts" setup>
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { computed, reactive, ref } from 'vue';

const props = defineProps<{
  value?: string;
}>();

const suggestedOptions = ['Color', 'Size', 'Material', 'Style'];

const select = computed(() => props.value ?? suggestedOptions[0]);
const query = ref(props.value ?? '');

const filteredSuggestedOptions = computed(() =>
  query.value === ''
    ? suggestedOptions
    : suggestedOptions.filter((suggestOp) =>
        suggestOp.toLowerCase().replace(/\s+/g, '').includes(query.value.toLowerCase().replace(/\s+/g, '')),
      ),
);

const emit = defineEmits<{
  (e: 'change-option-title', optionTitle: string): void;
}>();

const onChange = (value: string) => {
  query.value = value;
  emit('change-option-title', value);
};

const displayValue = (option: unknown) => {
  return (option as string) ?? select;
};
</script>

<template>
  <Combobox v-model="query" @update:model-value="onChange($event)">
    <div class="relative">
      <ComboboxInput
        class="text-14 rounded-medium h-40 w-[200px] border border-solid py-4 px-8 outline-none"
        :display-value="displayValue"
        @change="onChange($event.target.value)" />
      <ComboboxButton class="absolute inset-y-0 right-0 flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-20 w-20"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2">
          <path strokeLinecap="round" strokeLinejoin="round" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
        </svg>
      </ComboboxButton>

      <ComboboxOptions class="absolute z-50 w-full overflow-auto border border-solid bg-white focus:outline-none">
        <div
          v-if="filteredSuggestedOptions.length === 0 && query !== ''"
          class="text-14 relative cursor-default select-none px-4 text-gray-700">
          No option match.
        </div>

        <ComboboxOption
          v-for="option in filteredSuggestedOptions"
          :key="option"
          v-slot="{ selected, active }"
          as="template"
          :value="option">
          <li
            class="relative cursor-pointer select-none pl-10 pr-4"
            :class="{
              'text-primary-325': active,
              'text-gray-900': !active,
            }">
            <span
              class="block truncate"
              :class="{ 'text-primary-350 font-medium': selected, 'font-normal': !selected }">
              {{ option }}
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>
