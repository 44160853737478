<script lang="ts" setup>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ref } from 'vue';
import type { SwatchesOptionType } from '../types';

type Option = {
  label: string;
  type: SwatchesOptionType;
};

const emit = defineEmits<{
  (e: 'typeChange', value?: SwatchesOptionType): void;
}>();

const props = defineProps<{
  index: string;
  type?: SwatchesOptionType;
}>();

const optionType: Option[] = [
  { label: 'Color', type: 'color' },
  { label: 'Image', type: 'image' },
  { label: 'Rectangle List', type: 'rectangle_list' },
  // { label: 'Radio Buttons', type: 'radio_buttons' },
  { label: 'Dropdown', type: 'dropdown' },
  { label: 'Shopify Image', type: 'image_shopify' },
];

const initTypeSelected = (type?: SwatchesOptionType) => {
  if (type) {
    return optionType.filter((v) => v.type === type)[0];
  }
  return optionType[0];
};

const modelValue = ref(initTypeSelected(props.type));

const handleUpdate = (value: Option) => {
  console.log('handleUpdate', value);
  if (value.type) {
    emit('typeChange', value.type);
  }
};
</script>

<template>
  <Listbox
    v-model="modelValue"
    class="text-14 rounded-medium h-40 w-[150px] bg-white p-8"
    @update:model-value="handleUpdate">
    <div class="relative">
      <ListboxButton class="relative flex h-full w-full cursor-pointer items-center text-left">
        <span class="inline-block truncate">{{ modelValue.label }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-20 w-20"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
          </svg>
        </span>
      </ListboxButton>

      <ListboxOptions class="absolute left-0 top-40 z-50 w-full border border-solid bg-white">
        <ListboxOption
          v-for="option in optionType"
          :key="option.type"
          :value="option"
          class="hover:text-primary-325 cursor-pointer px-8 py-4">
          {{ option.label }}
        </ListboxOption>
      </ListboxOptions>
    </div>
  </Listbox>
</template>
