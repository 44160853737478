export type TypographyProps = {
  fontSize?: string;
  fontWeight?: string | number;
  fontStyle?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
};

export type FontItem = {
  family: string;
  variants: string[];
  subsets: string[];
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
