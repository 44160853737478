<template>
  <div class="gt_control gt_control-input">
    <slot name="label"></slot>
    <div class="gt-input-group" :disabled="readonly">
      <input
        :ref="'input-start'"
        v-model="inputContent[0]"
        type="text"
        :disabled="readonly"
        @keyup.stop="onChange"
        @change.stop="change"
        @focus.stop="focus"
        @blur.stop="blur" />
      <span>
        {{ fixContentKey.replace('[!', '').replace('!]', '') }}
      </span>
      <input
        :ref="'input-end'"
        v-model="inputContent[1]"
        type="text"
        :disabled="readonly"
        @keyup.stop="onChange"
        @change.stop="change"
        @focus.stop="focus"
        @blur.stop="blur" />
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

type PropsType = {
  id?: string | number;
  value?: any;
  readonly?: boolean;
};
const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  value: 0,
  readonly: false,
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

// const input = ref<any>();
const val = ref<any>(props.value);
const currentValue = ref(props.value);
const fixContentKey = ref('');
const inputContent = ref<any>();

watch(props.value, (value) => {
  val.value = value;
  currentValue.value = value;
  formatValue();
});

// currentValue.value = props.value;

const formatValue = () => {
  inputContent.value = [];
  fixContentKey.value = '';
  const contentFixIndex = currentValue.value.indexOf('[!');
  const arrValue = currentValue.value.replace(/\[!(.*?)!\]/g, ',').split(',');
  if (contentFixIndex === 0 && arrValue.length === 1) {
    inputContent.value[0] = null;
    inputContent.value[1] = arrValue[0];
  } else if (contentFixIndex !== 0 && arrValue.length === 1) {
    inputContent.value[1] = '';
    inputContent.value[0] = arrValue[0];
  } else {
    for (let i = 0; i < arrValue.length; i++) {
      inputContent.value.push(arrValue[i]);
    }
  }
  fixContentKey.value = props.value.match(/\[!(.*?)!\]/g) ? props.value.match(/\[!(.*?)!\]/g)[0] : '';
  convertStringReturn();
};

formatValue();

const convertStringReturn = () => {
  val.value = 0;
  val.value += inputContent.value[0] + fixContentKey.value + inputContent.value[1];
};
// General Methods
// const setValue = (value: any) => {
//   if (value != val.value) {
//     val.value = value;
//   }
// };
const onChange = () => {
  const delayTyping = setTimeout(() => {
    convertStringReturn();
    emit('controlOnChange', props.id, val.value);
  }, 300);
  clearTimeout(delayTyping);
};

const change = () => {
  convertStringReturn();
  if (val.value) {
    val.value = val.value.trim();
  }
  emit('controlChange', props.id, val.value);
};

// const triggerFocus = () => {
//   if (input.value) {
//     input.value.focus();
//   }
// };
const focus = () => {
  emit('controlFocus', props.id, val.value);
};
const blur = () => {
  emit('controlBlur', props.id, val.value);
};
</script>

<style lang="scss" scoped>
$blue: #839bc0;
$text: #333333;
$textShade2: #7f7f7f;
$textShade3: #dadada;

.gt-input-group {
  border: 1px solid #c5cbd7;
  border-radius: 3px;
  display: flex;
  overflow: hidden;
  height: 32px;
  padding: 6px 8px 5px;
  background-color: #ffffff;
  display: flex;
  align-items: center;

  span {
    background-color: #ebedf1;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 16px;
    min-width: 55px;
    height: 21px;
    border-radius: 3px;
    font-weight: 500;
  }

  input {
    display: inline-block;
    border: none;
    border-radius: 0;
    width: calc(50% - 22.5px);
    color: #43484b;
    font-size: 14px;
    line-height: 21px;
    padding: 0;
    outline: none;
    user-select: none;
  }

  &[disabled='true'] {
    border: none;
    background-color: #f0f3f6;
    color: #c5cbd7;
    cursor: not-allowed;

    span {
      background-color: #f0f3f6;
      cursor: not-allowed;
    }

    input {
      color: #c5cbd7;
      cursor: not-allowed;
    }
  }
}
</style>
