import type { Ref } from 'vue';
import { computed } from 'vue';
import { gridToArrayRegex } from '../../helpers/regex';

export const gridToArray = (layout?: string) => {
  return layout ? layout.match(gridToArrayRegex) ?? [] : [];
};
export const composeGridLayout = (value?: string) => {
  const layouts = gridToArray(value);
  return layouts.map((col) => {
    if (col === 'auto' || col.endsWith('px') || col.endsWith('fr')) {
      return col;
    }
    if (col.endsWith(')')) {
      const value = col.split(' ')?.[1];
      return value.slice(0, -1);
    }
    return col;
  });
};

export const useGridLayout = (value: Ref<string>) => {
  const customLayout = computed<string[]>(() => composeGridLayout(value.value));
  return customLayout;
};

export const useGridLayoutToArray = (value: Ref<string>) => {
  return computed<string[]>(() => gridToArray(value.value).map((v) => v.toString()));
};
