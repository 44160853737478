<script setup lang="ts">
import { ref } from 'vue';
import SizePopup from './SizePopup.vue';
import type { SizeProps, SizeSetting } from './type';
import type { OptionSelect } from '@gem/uikit';

type Props = {
  id: string;
  mobileOnly?: boolean;
  value?: SizeSetting;
  devices?: string[];
  deviceValues?: Record<string, SizeProps>;
  component?: 'tag' | string;
  currentScreen?: string;
  componentTag?: string;
  hasNoneValue?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: Props['value']): void;
  (e: 'controlOnChange', controlId: string, value: Props['value']): void;
  (e: 'changeScreen', screenId: string): void;
}>();

let types: { id: any; name: string }[] = [
  { name: 'Small', id: 'small' },
  { name: 'Medium', id: 'medium' },
  { name: 'Large', id: 'large' },
];

if (props.hasNoneValue) {
  types = [{ name: 'None', id: 'none' }, ...types];
}

const initSelectedType = types.find((v) => v.id == props.value?.type) ?? { name: 'Custom', id: 'custom' };
const selectedType = ref(initSelectedType);
const isSaveVisible = ref(false);
const isChange = ref(false);
const initDeviceValues = ref(props.deviceValues);

const changeType = (_option: OptionSelect, id: string | number) => {
  const selected = types.find((v) => v.id == id);
  if (selected) {
    selectedType.value = selected;
    emit('controlChange', props.id, { type: selectedType.value.id });
  }
};

const close = () => {
  if (selectedType.value.name !== 'custom' && isChange.value) isSaveVisible.value = true;
};

function resetValue() {
  emit('controlChange', props.id, {
    type: selectedType.value.id,
    custom: initDeviceValues.value,
  });
  isChange.value = false;
}

const onChange = (device: string, value?: SizeProps) => {
  if (!isChange.value && selectedType.value.id) {
    isChange.value = true;
  }
  const newValue = {
    ...props?.deviceValues,
    [device]: {
      ...props.deviceValues?.[device],
      ...value,
    },
  };
  selectedType.value = { name: 'Custom', id: 'custom' };
  emit('controlChange', props.id, {
    custom: newValue,
  });
};
const onChangeData = (device: string, value?: SizeProps) => {
  if (!isChange.value && selectedType.value.id) {
    isChange.value = true;
  }
  const newValue = {
    ...props?.deviceValues,
    [device]: {
      ...props.deviceValues?.[device],
      ...value,
    },
  };
  selectedType.value = { name: 'Custom', id: 'custom' };
  emit('controlOnChange', props.id, {
    custom: newValue,
  });
};
</script>

<template>
  <div class="w-full">
    <div class="flex h-36 w-full items-center gap-8">
      <div class="h-full flex-1">
        <g-select :options="types" :active-id="selectedType.id" @select="changeType">
          <template #button-content>
            <span class="text-12 inline-block truncate">{{ selectedType.name }}</span>
          </template>
          <template #content="{ item, onSelect, isActive, isHoverSelect }">
            <div
              class="hover:bg-light-100/20 rounded-medium text-light-100 text-12 group relative flex cursor-pointer select-none items-center truncate whitespace-nowrap py-8 pl-24 pr-10 leading-[14px]"
              :class="{ 'bg-light-low !text-dark-high': isActive && !isHoverSelect }"
              :data-value="item.id"
              @click="onSelect(item)">
              <span v-if="isActive" class="absolute inset-y-0 left-4 flex items-center">
                <GBaseIcon name="status-check" width="16" height="16" />
              </span>
              <p class="font-regular flex w-full items-center justify-between truncate">
                <span class="truncate">{{ item.name }}</span>
                <span class="text-dark-low">{{ item.value }}</span>
              </p>
            </div>
          </template>
        </g-select>
      </div>
      <SizePopup
        :component="component"
        :is-custom="props?.value?.custom"
        :current-type="props.value?.type"
        :devices="devices"
        :value="deviceValues"
        :mobile-only="mobileOnly"
        :has-change="isChange"
        :component-tag="componentTag"
        :current-screen="currentScreen"
        @reset="resetValue"
        @change-screen="$emit('changeScreen', $event)"
        @change="onChange"
        @on-change="onChangeData"
        @close="close" />
    </div>
  </div>
</template>
