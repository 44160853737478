<script lang="ts" setup>
import { reactive, ref, watch } from 'vue';
import type { ProductOption, SelectedOption } from '../../type/common';
import { computed } from 'vue';
import useSwatchesStore from '../../../../../apps/gemx/src/modules/editor/modules/settings/stores/swatches';
import VariantsItem from './VariantsItem.vue';
import type { GlobalSwatchesData, SwatchesOptionType } from '@gem/control/src/control/swatches/types';
import type { GlobalSettingCreateOrUpdateMutationVariables } from '@/api/app/mutations/global-setting-create-or-update.generated';
import { useGlobalSettingCreateOrUpdateMutation } from '@/api/app/mutations/global-setting-create-or-update.generated';
import { builderUpdateSwatchesData } from 'gemx/src/modules/editor/modules/preview/utils/builder';

type ProductSetting = {
  productId?: string;
  initialVariantId?: string;
  initialVariantBaseId?: string;
  productHandle?: string;
};

type Props = {
  id?: string;
  value?: ProductSetting;
  options?: ProductOption[];
  variants?: SelectedOption[];
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'openModalEditStyle', optionType: SwatchesOptionType): void;
}>();

const swatchesStore = useSwatchesStore();
const updateSwatchesGlobalSetting = useGlobalSettingCreateOrUpdateMutation();
const dataSwatches = computed(() => swatchesStore.getDataSwatches);

const variantSelected = ref<{ [key: string]: any }>(
  props.variants?.reduce((a, b) => {
    if (b.name) {
      return { ...a, [b.name]: b.value };
    }
    return a;
  }, {}) ?? {},
);

const handleOpenModalEditStyle = (optionType: SwatchesOptionType) => {
  emit('openModalEditStyle', optionType);
};

watch(
  () => props.variants,
  (newVariants) => {
    variantSelected.value =
      newVariants?.reduce((a, b) => {
        if (b.name) {
          return { ...a, [b.name]: b.value };
        }
        return a;
      }, {}) ?? {};
  },
);

const updateSwatchesConfig = (dataSwatches: GlobalSwatchesData[]) => {
  const variable = reactive<GlobalSettingCreateOrUpdateMutationVariables>({
    input: {
      key: 'global_swatches',
      value: JSON.stringify(dataSwatches),
    },
  });

  updateSwatchesGlobalSetting.mutate(variable);
  swatchesStore.setDataSwatches(dataSwatches);
  builderUpdateSwatchesData(dataSwatches);
};
</script>
<template>
  <VariantsItem
    v-for="(item, index) in options"
    :key="index"
    :data-swatches="dataSwatches"
    :option="item"
    @open-modal-edit-style="handleOpenModalEditStyle"
    @change-option-type="updateSwatchesConfig" />
</template>
