<script setup lang="ts">
import type { OptionSelect } from '@gem/uikit';
import { computed, ref, watch } from 'vue';
import type { FontItem, TypographyProps } from './types';
import TypographyPopupSetting from './TypographyPopupSetting.vue';

type Props = {
  id: string;
  mobileOnly?: boolean;
  value?: {
    type?: string;
    custom?: Record<string, TypographyProps>;
  };
  overlayContainer?: string;
  typos?: { id: string; name: string; value?: string }[];
  devices?: string[];
  deviceValues?: Record<string, TypographyProps>;
  font?: Record<string, FontItem>;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: Props['value']): void;
  (e: 'controlOnChange', controlId: string, value: Props['value']): void;
  (e: 'saveToGlobalStyles', value: string): void;
  (e: 'changeScreen', screenId: string): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
}>();
const initDeviceValues = ref(props.deviceValues);
const selectedTypo = ref(props.value?.type);
const isChange = ref(false);
// const isSaveVisible = ref(false);

const typoName = computed(() => {
  return props.typos?.find((typo) => typo.id === selectedTypo.value)?.name ?? 'Custom';
});

const close = () => {
  if (selectedTypo.value && isChange.value) {
    cancel();
  }
};

const save = () => {
  if (isChange.value && selectedTypo.value) {
    // isSaveVisible.value = false;
    isChange.value = false;
    emit('saveToGlobalStyles', selectedTypo.value);
  }
};

const cancel = () => {
  isChange.value = false;
  // isSaveVisible.value = false;
  selectedTypo.value = undefined;
};

const change = (device: string, name: string, value?: string) => {
  if (!isChange.value && selectedTypo.value) {
    isChange.value = true;
  }

  let newValue: Record<string, TypographyProps> = {};
  if (props.mobileOnly) {
    newValue = {
      desktop: {
        ...props.deviceValues?.desktop,
        ...props.deviceValues?.[device],
        [name]: value,
      },
    };
  } else {
    newValue = {
      ...props?.deviceValues,
      [device]: {
        ...props.deviceValues?.[device],
        [name]: value,
      },
    };
  }
  emit('controlChange', props.id, {
    custom: newValue,
  });
};
const onChange = (device: string, name: string, value?: string) => {
  if (!isChange.value && selectedTypo.value) {
    isChange.value = true;
  }
  let newValue: Record<string, TypographyProps> = {};
  if (props.mobileOnly) {
    newValue = {
      desktop: {
        ...props.deviceValues?.desktop,
        ...props.deviceValues?.[device],
        [name]: value,
      },
    };
  } else {
    newValue = {
      ...props?.deviceValues,
      [device]: {
        ...props.deviceValues?.[device],
        [name]: value,
      },
    };
  }
  emit('controlOnChange', props.id, {
    custom: newValue,
  });
};

function resetValue() {
  emit('controlChange', props.id, {
    type: selectedTypo.value,
    custom: initDeviceValues.value,
  });
  isChange.value = false;
}

const changeTypo = (_: OptionSelect, id: string | number) => {
  selectedTypo.value = id.toString();
};

function onClickSubAction(type: string, value?: any) {
  emit('onClickSubAction', type, value);
}

watch(selectedTypo, (newType) => {
  if (newType) {
    isChange.value = false;
    emit('controlChange', props.id, {
      type: newType,
    });
  }
});
</script>

<template>
  <div class="w-full">
    <div class="flex h-36 w-full items-center gap-8">
      <div class="h-full flex-1">
        <g-select :options="typos" :active-id="selectedTypo" @select="changeTypo">
          <template #button-content>
            <span class="text-12 inline-block truncate">{{ typoName }}</span>
          </template>
          <template #content="{ item, onSelect, isActive, isHoverSelect }">
            <div
              class="hover:bg-light-100/20 rounded-medium text-light-100 text-12 group relative flex cursor-pointer select-none items-center truncate whitespace-nowrap py-8 pl-24 pr-10 leading-[14px]"
              :class="{ 'bg-light-low !text-dark-high': isActive && !isHoverSelect }"
              :data-value="item.id"
              @click="onSelect(item)">
              <span v-if="isActive" class="absolute inset-y-0 left-4 flex items-center">
                <GBaseIcon name="status-check" width="16" height="16" />
              </span>
              <p class="font-regular flex w-full items-center justify-between truncate">
                <span class="truncate">{{ item.name }}</span>
                <span class="text-dark-low">{{ item.value }}</span>
              </p>
            </div>
          </template>
          <template #footer>
            <p
              class="text-12 text-primary-200 border-light-500/[0.15] mx-4 cursor-pointer border-t pt-8 pb-4 text-center font-medium leading-[18px]"
              @click.stop="onClickSubAction('editGlobalStyle', 'gs-typography')">
              Edit global style
            </p>
          </template>
        </g-select>
      </div>
      <TypographyPopupSetting
        :font="font"
        :devices="devices"
        :value="deviceValues"
        :has-change="isChange"
        :title="typoName"
        :overlay-container="overlayContainer"
        :mobile-only="mobileOnly"
        @reset="resetValue"
        @change-screen="$emit('changeScreen', $event)"
        @change="change"
        @on-change="onChange"
        @save-data="save"
        @close="close"
        @on-click-sub-action="onClickSubAction" />
    </div>
  </div>
</template>
