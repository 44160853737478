<template>
  <div
    v-show="statusShow"
    class="shadow-2dp bg-dark-300 popup_insert-link rounded-medium z-layer absolute top-full left-0 w-[240px] py-12 px-8 ring-1 ring-black">
    <h3 class="text-dark-high mb-16 font-semibold">Edit Link</h3>
    <form @submit="submit">
      <div class="mb-16">
        <label for="InputUrlInsertLink" class="text-12 text-dark-low mb-4">Link to: </label>
        <input
          v-model="valUrl"
          type="url"
          class="rounded-large text-12 box-border w-full cursor-text border border-transparent p-10 text-white outline-none"
          placeholder="Url" />
        <span class="text-10 text-dark-low mb-4 italic">To what URL should this link go?</span>
      </div>
      <div class="mb-16">
        <label for="TextInsertLink" class="text-12 text-dark-low mb-4">Text to display: </label>
        <div
          id="TextInsertLink"
          contenteditable="true"
          class="rounded-large text-12 box-border w-full cursor-text border border-transparent p-10 text-white outline-none"
          v-html="valTextUrl" />
      </div>
      <div class="form-group">
        <!-- <Toggler :value="valOpenNewTab" title="Open New Tab (On/Off)" @change="changeOpenNewTabStatus" /> -->
      </div>
      <input
        v-model="valOpenNewTab"
        class="rounded-large text-12 box-border w-full cursor-text border border-transparent p-10 text-white outline-none"
        type="checkbox"
        hidden />
      <div class="flex flex-row justify-between">
        <g-button type="grey" size="medium" @click="cancelInsertLink"> Cancel </g-button>
        <g-button button-type="submit" type="primary" size="medium">Insert</g-button>
      </div>
    </form>
  </div>
</template>

<script>
// import Toggler from '@/components/editor/control/Toggler';
export default {
  name: 'PopupInsertLink',
  props: {
    statusDisplay: Boolean,
    valTextInsertLink: String,
    link: String,
    classButton: String,
    isOpenNewTab: Boolean,
  },
  // components: {
  //   Toggler,
  // },
  emits: ['status-display-popup-insert-link', 'submit-form-insert-link', 'statusDisplayPopupInsertLink'],
  data() {
    return {
      statusShow: false,
      valUrl: '',
      valTextUrl: '',
      valOpenNewTab: false,
    };
  },
  watch: {
    valTextInsertLink(newVal) {
      this.valTextUrl = newVal;
    },
    link(newVal) {
      this.valUrl = newVal;
    },
    isOpenNewTab(newVal) {
      this.valOpenNewTab = newVal;
    },
  },
  created() {},
  methods: {
    changeOpenNewTabStatus(id, val) {
      this.openNewTab = val;
    },
    cancelInsertLink() {
      event.preventDefault();

      this.statusShow = false;
      this.$emit('statusDisplayPopupInsertLink', this.statusShow);
    },
    submit() {
      event.preventDefault();
      this.valTextUrl = document.getElementById('TextInsertLink').innerHTML;
      const data = {
        text: this.valTextUrl,
        url: this.valUrl,
        openNewTab: this.openNewTab,
        type: 'CreateLink',
      };

      this.$emit('submit-form-insert-link', data);
      this.setStatus(false);
    },
    setStatus(val) {
      this.statusShow = val;
      this.$emit('status-display-popup-insert-link', this.statusShow);

      if (this.statusShow == true) {
        const _thisFn = this;
        window.addEventListener('click', function () {
          const $parent = event.target.closest('.popup_insert-link');
          const $button = event.target.closest(_thisFn.$props.classButton);
          if (!$parent && !$button) {
            _thisFn.statusShow = false;
            _thisFn.$emit('statusDisplayPopupInsertLink', false);
          }
        });
      }
    },
    closePopup() {},
  },
};
</script>
