import type { FontItem } from '@/composables/useGoogleFonts';
import type { NestedKeys } from './custom';
import type { TypographyV2Attrs, TypographyV2Props } from '@gem/control';
export type TypographyProps = {
  fontSize?: string;
  fontWeight?: string | number;
  fontStyle?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
};

export type TypographyV2Value = {
  type?: TypographyType;
  custom?: TypographyV2Props;
  attrs?: TypographyV2Attrs;
};

export type ShadowType = 'shadow-1' | 'shadow-2' | 'shadow-3';

export type ShadowProps = {
  type?: ShadowType | 'custom';
  angle?: string | number;
  distance?: string;
  blur?: string;
  spread?: string;
  color?: ColorType | string;
};

export type ColorProps = {
  colorType?: CustomColorType;
  color?: string;
};

export type ColorType =
  | NestedKeys<BrandColorObject>
  | NestedKeys<BackgroundColorObject>
  | NestedKeys<TextColorObject>
  | NestedKeys<LineColorObject>
  | NestedKeys<FuncColorObject>;

export type CustomColorType = ColorType | 'custom' | 'transparent';

type BrandColorObject = {
  brand: string;
  highlight: string;
};

type BackgroundColorObject = {
  'bg-1': string;
  'bg-2': string;
  'bg-3': string;
  'background-body': string;
};

type TextColorObject = {
  'text-1': string;
  'text-2': string;
  'text-3': string;
};

type LineColorObject = {
  'line-1': string;
  'line-2': string;
  'line-3': string;
};

type FuncColorObject = {
  info: string;
  warning: string;
  success: string;
  error: string;
};

export type TypographyType =
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'subheading-1'
  | 'subheading-2'
  | 'subheading-3'
  | 'paragraph-1'
  | 'paragraph-2'
  | 'paragraph-3';

export type ObjectDeviceGlobalType<T> = {
  desktop: T | undefined;
  tablet?: T;
  mobile?: T;
};

export type SpacingType = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | 'container';
export type RoundedSize = 'small' | 'medium' | 'large';
export type Container = 'width' | 'padding';

export type FontName = 'body' | 'heading' | 'code';
export type GlobalStyleResponsiveConfig = {
  color: Partial<Record<ColorType, string>> &
    Partial<Record<'my-colors', string[]>> &
    Partial<Record<'theme-colors', string[]>> &
    Partial<Record<'color-index-change', number>>;

  font: Partial<Record<FontName, FontItem>>;
  typography: Partial<Record<TypographyType, ObjectDeviceGlobalType<TypographyProps>>>;
  spacing: Partial<Record<SpacingType, ObjectDeviceGlobalType<string>>>;
  container: Partial<Record<Container, ObjectDeviceGlobalType<string>>>;
  radius: Partial<Record<RoundedSize, string>>;
  fontFaces?: string[];
};

export type GlobalStyleConfig = {
  color?: Partial<Record<ColorType, string>>;
  typography?: Partial<Record<TypographyType, TypographyProps>>;
  spacing?: Partial<Record<SpacingType, string>>;
  radius?: Partial<Record<RoundedSize, string>>;
};

export type GlobalFixedPresetConfig = {
  shadow: Partial<Record<ShadowType, ShadowProps>>;
};

export const globalStyleSample: GlobalStyleResponsiveConfig = {
  color: {
    brand: '#2352E7',
    highlight: '#FDAC2B',
    'bg-1': '#F7F7F7',
    'bg-2': '#FBFBFB',
    'bg-3': '#FFFFFF',
    'text-1': '#242424',
    'text-2': '#FBFBFB',
    'text-3': '#FFFFFF',
    'line-1': '#F3F3F3',
    'line-2': '#EEEEEE',
    'line-3': '#E0E0E0',
    info: '#1890FF',
    warning: '#FAAD14',
    success: '#52C41A',
    error: '#EA3335',
  },
  font: {},
  typography: {
    'heading-1': {
      desktop: {
        fontSize: '57px',
        fontWeight: '700',
        lineHeight: '120%',
      },
      tablet: {
        fontSize: '50px',
      },
      mobile: {
        fontSize: '50px',
      },
    },
    'heading-2': {
      desktop: {
        fontSize: '48px',
        fontWeight: '700',
        lineHeight: '120%',
      },
      tablet: {
        fontSize: '42px',
      },
      mobile: {
        fontSize: '42px',
      },
    },
    'heading-3': {
      desktop: {
        fontSize: '40px',
        fontWeight: '700',
        lineHeight: '120%',
      },
      tablet: {
        fontSize: '35px',
      },
      mobile: {
        fontSize: '35px',
      },
    },
    'subheading-1': {
      desktop: {
        fontSize: '33px',
        fontWeight: '700',
        lineHeight: '150%',
      },
      tablet: {
        fontSize: '29px',
      },
      mobile: {
        fontSize: '29px',
      },
    },
    'subheading-2': {
      desktop: {
        fontSize: '28px',
        fontWeight: '700',
        lineHeight: '150%',
      },
      tablet: {
        fontSize: '24px',
      },
      mobile: {
        fontSize: '24px',
      },
    },
    'subheading-3': {
      desktop: {
        fontSize: '23px',
        fontWeight: '700',
        lineHeight: '150%',
      },
      tablet: {
        fontSize: '20px',
      },
      mobile: {
        fontSize: '20px',
      },
    },
    'paragraph-1': {
      desktop: {
        fontSize: '16px',
        fontWeight: '400',
        lineHeight: '150%',
      },
      tablet: {
        fontSize: '14px',
      },
      mobile: {
        fontSize: '14px',
      },
    },
    'paragraph-2': {
      desktop: {
        fontSize: '13px',
        fontWeight: '400',
        lineHeight: '150%',
      },
      tablet: {
        fontSize: '12px',
      },
      mobile: {
        fontSize: '12px',
      },
    },
  },
  spacing: {
    xxs: {
      desktop: '0.125rem',
    },
    xs: {
      desktop: '0.25rem',
    },
    s: {
      desktop: '0.5rem',
    },
    m: {
      desktop: '0.75rem',
    },
    l: {
      desktop: '1rem',
    },
    xl: {
      desktop: '1.5rem',
    },
    '2xl': {
      desktop: '2rem',
    },
    '3xl': {
      desktop: '3rem',
    },
    '4xl': {
      desktop: '5rem',
    },
    '5xl': {
      desktop: '7rem',
    },
  },
  container: {
    width: {
      desktop: '1200px',
      tablet: '100%',
      mobile: '100%',
    },
    padding: {
      desktop: '15px',
    },
  },
  radius: {
    small: '3px',
    medium: '6px',
    large: '16px',
  },
};

export const fixedPresetConfig: GlobalFixedPresetConfig = {
  shadow: {
    'shadow-1': {
      type: 'shadow-1',
      distance: '4px',
      blur: '12px',
      spread: '0px',
      color: 'rgba(0, 0, 0, 0.20)',
      angle: 90,
    },
    'shadow-2': {
      type: 'shadow-2',
      distance: '4px',
      blur: '12px',
      spread: '0px',
      color: 'rgba(18, 18, 18, 0.2)',
      angle: 90,
    },
    'shadow-3': {
      type: 'shadow-3',
      distance: '8px',
      blur: '24px',
      spread: '2px',
      color: 'rgba(18, 18, 18, 0.2)',
      angle: 90,
    },
  },
};
