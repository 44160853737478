import { reactive } from 'vue';

export type Store = {
  getters: {
    getDataMenu: () => string;
    getItemDragId: () => string;
    getItemDragIdDrag: () => string;
    getItemDragX: () => number;
    getItemDragY: () => number;
    getItemDragHeight: () => number;
    getItemDragIsDrag: () => boolean;
    getItemDragIsChildren: () => boolean;
    getItemDragMouseMove: () => boolean;
    getDropZoneId: () => string;
  };
  actions: {
    setDataMenu: (menu: Data) => void;
    setItemDragId: (id: string) => void;
    setItemDragIdDrag: (id: string) => void;
    setItemDragX: (x: number) => void;
    setItemDragY: (y: number) => void;
    setItemDragHeight: (height: number) => void;
    setItemDragIsDrag: (isDrag: boolean) => void;
    setItemDragIsChildren: (value: boolean) => void;
    setItemDragMouseMove: (value: boolean) => void;
    setDropZoneId: (id: string) => void;
  };
};

export type Root = {
  id?: string;
  title?: string;
  childrens: string[];
};

export type SubMenu = {
  id: string;
  title: string;
  link?: string;
  target?: string;
  childrens: string[];
};

export type Menu = {
  [key: string]: SubMenu;
};

export type Data = {
  ROOT: Root;
} & Menu;

export type State = {
  data: {
    menu: Data;
  };
  itemDrag: {
    id: string;
    idDrag: string;
    x: number;
    y: number;
    height: number;
    isDrag: boolean;
    isChildren: boolean;
    mouseMove: boolean;
  };
  dropZone: {
    id: string;
  };
};

const state = reactive<State>({
  data: {
    menu: {
      ROOT: {
        id: 'ROOT',
        title: 'Root',
        childrens: [],
      },
    },
  },
  itemDrag: {
    id: '', // ID for handle MoveItem
    idDrag: '', // ID for add class drag
    x: 0,
    y: 0,
    height: 0,
    isDrag: false,
    isChildren: true,
    mouseMove: false,
  },
  dropZone: {
    id: '',
  },
});

const getters = {
  getDataMenu() {
    return state.data.menu;
  },
  getItemDragId() {
    return state.itemDrag.id;
  },
  getItemDragIdDrag() {
    return state.itemDrag.idDrag;
  },
  getItemDragX() {
    return state.itemDrag.x;
  },
  getItemDragY() {
    return state.itemDrag.y;
  },
  getItemDragHeight() {
    return state.itemDrag.height;
  },
  getItemDragIsDrag() {
    return state.itemDrag.isDrag;
  },
  getItemDragIsChildren() {
    return state.itemDrag.isChildren;
  },
  getItemDragMouseMove() {
    return state.itemDrag.mouseMove;
  },
  getDropZoneId() {
    return state.dropZone.id;
  },
};

const actions = {
  setDataMenu(menu: Data) {
    state.data.menu = menu;
  },
  setItemDragId(id: string) {
    state.itemDrag.id = id;
  },
  setItemDragIdDrag(id: string) {
    state.itemDrag.idDrag = id;
  },
  setItemDragX(x: number) {
    state.itemDrag.x = x;
  },
  setItemDragY(y: number) {
    state.itemDrag.y = y;
  },
  setItemDragHeight(height: number) {
    state.itemDrag.height = height;
  },
  setItemDragIsDrag(isDrag: boolean) {
    state.itemDrag.isDrag = isDrag;
  },
  setItemDragIsChildren(value: boolean) {
    state.itemDrag.isChildren = value;
  },
  setItemDragMouseMove(value: boolean) {
    state.itemDrag.mouseMove = value;
  },
  setDropZoneId(id: string) {
    state.dropZone.id = id;
  },
};

export default { state, getters, actions };
