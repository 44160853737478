import type { TypographyV2Attrs, TypographyV2Props } from '../../types';

export type TypographyType =
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'subheading-1'
  | 'subheading-2'
  | 'subheading-3'
  | 'paragraph-1'
  | 'paragraph-2'
  | 'paragraph-3';

export type TypographyProps = {
  fontSize?: string;
  fontWeight?: string | number;
  fontStyle?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
};

export type TypographyValue = {
  type?: TypographyType;
  custom?: TypographyV2Props;
  attrs?: TypographyV2Attrs;
};

export type FontItem = {
  family: string;
  variants: string[];
  subsets: string[];
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
