<script lang="ts" setup>
import { ref, watch } from 'vue';

import { useInfiniteScroll } from '@vueuse/core';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@headlessui/vue';
import { GButton } from '@gem/uikit';
import PagesTypeTab from '../pick-link/PagesTypeTab.vue';
import { TabType } from '../../type/pickLink';

type List = { title?: string; handle?: string; id?: string };
// props & emit
type Props = {
  value: { link?: string; target?: string; title?: string };
  list?: List[];
  loading?: boolean;
  type?: 'Product' | 'Collection' | 'Blog' | 'Article';
  targets: { label: string; value: string }[];
  instant?: boolean;
  shopDomain?: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'showMore'): void;
  (e: 'refresh'): void;
  (e: 'onSearch', value?: string): void;
  (e: 'save', value?: { link: string; target: string; title: string }): void;
  (e: 'onSetTabSelected', tab?: TabType): void;
}>();
// const

const val = ref(props.value);
const isDisableSave = ref(false);

watch(
  () => props.value,
  (nV, oV) => {
    if (nV !== oV) {
      val.value = nV;
    }
  },
);

const productInfinityScroll = ref<HTMLElement | null>(null);
const customUrl = ref(val.value.link ?? '/');
const customTitle = ref(val.value.title ?? '');
const target = props.targets.find((v) => v.value === val.value.target);
const selectedTargets = ref<{ label: string; value: string }>(target ?? { label: 'Current Tab', value: '_self' });
const selectedTab = ref<string>('pages');

const categories = props.instant
  ? {
      pages: PagesTypeTab,
      scrollToList: PagesTypeTab,
      emails: PagesTypeTab,
      phone: PagesTypeTab,
    }
  : {
      pages: PagesTypeTab,
      collections: PagesTypeTab,
      products: PagesTypeTab,
      articles: PagesTypeTab,
      scrollToList: PagesTypeTab,
      emails: PagesTypeTab,
      phone: PagesTypeTab,
    };

// methods
useInfiniteScroll(
  productInfinityScroll,
  () => {
    // load more
    emit('showMore');
  },
  { distance: 100 },
);
const handleSelectedTab = (value?: string) => {
  if (value) {
    selectedTab.value = value;
    emit('onSetTabSelected', value as TabType);
  }
  onHandleDisable(false);
};
const close = () => {
  emit('close');
};
const setCustomTitle = (title: string) => {
  customTitle.value = title;
};
const setCustomLink = (val: string) => {
  try {
    customUrl.value = new URL(val).pathname;
  } catch (e) {
    customUrl.value = val;
  }
};
const onSearch = (value: string) => {
  emit('onSearch', value);
};
const clear = () => {
  customUrl.value = '';
};

const getTitleTab = (type: TabType) => {
  switch (type) {
    case 'pages':
      return 'Landing pages';
    case 'collections':
      return 'Collections';
    case 'products':
      return 'Products';
    case 'articles':
      return 'Blog posts';
    case 'scrollToList':
      return 'Scroll to';
    case 'emails':
      return 'Email';
    case 'phone':
      return 'Phone';
    default:
      return '';
  }
};

const onHandleDisable = (disable: boolean) => {
  isDisableSave.value = disable;
};
</script>
<template>
  <Teleport to="body">
    <g-modal
      :is-open="true"
      :hide-actions="true"
      :hide-header="true"
      modal-class="xl:!h-[690px] !h-[690px] lg:w-[600px] z-[50] rounded-medium !max-h-[calc(100vh_-_72px)]"
      container-class="left-[280px] top-[56px] max-h-[calc(100%_-_72px)]"
      @close="close">
      <div class="flex h-full flex-col">
        <!-- header -->
        <div class="bg-light-100">
          <div class="flex items-center justify-between p-16 py-12 pr-8">
            <div class="text-16 text-light-high font-medium">Link picker</div>
            <button
              class="bg-light-100 !text-light-high hover:bg-light-400 rounded-medium flex h-32 w-32 items-center justify-center"
              @click="close">
              <g-base-icon name="close" width="16" height="16" />
            </button>
          </div>
          <div class="bg-dark-200/10 h-[1px] w-full" />
          <div class="flex gap-16 px-16 pt-16">
            <div class="flex-1">
              <p class="text-14 text-light-high mb-8 font-medium">Custom URL</p>
              <div class="rounded-medium border-light-500 relative flex w-full items-center border">
                <input
                  v-model="customUrl"
                  type="text"
                  class="text-12 text-light-high block h-[32px] w-[calc(100%_-_24px)] p-8 outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                  placeholder="E.g https://gempages.net" />
                <button
                  type="button"
                  class="absolute inset-y-0 right-0 flex h-full w-24 items-center"
                  :class="{
                    'visible opacity-100': !!customUrl.length,
                    'invisible opacity-0': !customUrl.length,
                  }"
                  @click="clear()">
                  <svg class="h-16 w-16" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 11L21 21M21 11L11 21" stroke="#676767" stroke-width="2" stroke-linecap="round" />
                    <circle cx="16" cy="16" r="13" stroke="#676767" stroke-width="2" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="w-[152px]">
              <p class="text-14 text-light-high mb-8 font-medium">Target</p>
              <Listbox v-model="selectedTargets">
                <div class="border-light-500 rounded-medium relative border">
                  <ListboxButton
                    class="rounded-small relative flex h-[32px] w-full cursor-pointer items-center justify-between bg-white px-8 pl-12 text-left focus:outline-none">
                    <span
                      class="text-12 font-regular text-light-high block truncate outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                      >{{ selectedTargets.label }}</span
                    >
                    <div class="flex h-24 w-24 items-center justify-center">
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M4.13017 6.11716C4.30374 5.96095 4.58515 5.96095 4.75871 6.11716L8 9.03431L11.2413 6.11716C11.4149 5.96095 11.6963 5.96095 11.8698 6.11716C12.0434 6.27337 12.0434 6.52663 11.8698 6.68284L8.31427 9.88284C8.1407 10.0391 7.8593 10.0391 7.68573 9.88284L4.13017 6.68284C3.95661 6.52663 3.95661 6.27337 4.13017 6.11716Z"
                          fill="#676767" />
                      </svg>
                    </div>
                  </ListboxButton>
                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      class="rounded-medium shadow-4dp absolute z-10 mt-[3px] h-fit w-full overflow-auto bg-white py-4 focus:outline-none">
                      <ListboxOption
                        v-for="t in props.targets"
                        v-slot="{ active, selected }"
                        :key="t.label"
                        :value="t"
                        as="template">
                        <li
                          :class="[
                            selected ? ' bg-black/10' : active ? 'bg-black/[.05]' : '',
                            'text-12 rounded-medium  text-light-high relative mx-4 flex cursor-pointer select-none items-center p-8 outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-white',
                          ]">
                          <svg
                            v-if="selected"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.3981 4.24117C13.5933 4.43644 13.5933 4.75302 13.3981 4.94828L6.10551 12.2409C6.01174 12.3346 5.88456 12.3873 5.75195 12.3873C5.61934 12.3873 5.49216 12.3346 5.39839 12.2408L2.60152 9.44385C2.40626 9.24858 2.40627 8.932 2.60153 8.73674C2.7968 8.54149 3.11338 8.54149 3.30864 8.73676L5.75196 11.1802L12.691 4.24117C12.8862 4.04591 13.2028 4.04591 13.3981 4.24117Z"
                              fill="#00C853" />
                          </svg>
                          <div v-else class="h-16 w-16" />

                          <span class="font-regular ml-8">{{ t?.label }}</span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
        <!-- body -->
        <div class="mt-8 flex-1 overflow-hidden">
          <div class="flex h-full flex-col overflow-hidden">
            <div class="flex w-full flex-1 flex-col overflow-hidden sm:px-0">
              <TabGroup>
                <TabList
                  class="border-dark-200/10 flex border-b-[1px] bg-white px-16"
                  :class="{
                    'justify-between': !instant,
                  }">
                  <Tab v-for="category in Object.keys(categories)" :key="category" v-slot="{ selected }" as="template">
                    <button
                      :class="[
                        'text-14 mx-8 h-[40px] py-8  font-medium leading-5',
                        'ring-white ring-opacity-60 ring-offset-2  focus:outline-none',
                        selected
                          ? 'border-b-primary-300 text-light-high border-b-2 '
                          : 'border-b-light-100 text-light-low border-b-2',
                      ]"
                      @click="handleSelectedTab(category)">
                      {{ getTitleTab(category) }}
                    </button>
                  </Tab>
                </TabList>
                <TabPanels class="flex-1 overflow-hidden">
                  <TabPanel
                    v-for="posts in Object.values(categories)"
                    :key="posts"
                    :class="['h-full bg-white', 'ring-white ring-opacity-60 ring-offset-2 focus:outline-none']">
                    <div class="h-full">
                      <PagesTypeTab
                        :is="posts"
                        :shop-domain="shopDomain"
                        :type="(selectedTab as TabType)"
                        :list="list"
                        :custom-url="value.link"
                        :link="customUrl"
                        :is-instant="instant"
                        :loading="loading"
                        @on-handle-disable="onHandleDisable"
                        @set-custom-title="setCustomTitle"
                        @set-custom-url="setCustomLink"
                        @show-more="emit('showMore')"
                        @on-search="onSearch" />
                    </div>
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            </div>
            <!-- footer -->
            <div class="border-t-dark-200/10 flex h-[64px] items-center justify-end border-t-[1px] pr-16">
              <GButton
                :disable="isDisableSave"
                button-classes="h-[40px] w-[81px] flex justify-center"
                @click="emit('save', { link: customUrl, target: selectedTargets.value, title: customTitle })">
                <span class="text-14 text-light-100 font-medium">Save</span>
              </GButton>
            </div>
          </div>
        </div>
      </div>
    </g-modal>
  </Teleport>
</template>
