<script lang="ts" setup>
import SwatchesDropdown from './components/SwatchesDropdown.vue';
import type { SwatchesOptionType, SwatchesOptionValue } from './types';

const emit = defineEmits<{
  (e: 'change', value: SwatchesOptionValue[]): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = defineProps<{
  index: string;
}>();

const handleChangeType = (type?: SwatchesOptionType) => {
  if (type) {
    emit('typeChange', +props.index, type);
  }
};

const sampleRectangleListData = [
  { label: 'Extra Small' },
  { label: 'S' },
  { label: 'M' },
  { label: 'L' },
  { label: 'Extra Large' },
];
</script>

<template>
  <div class="bg-light-400 flex gap-44 p-20">
    <div class="flex flex-col">
      <div class="text-light-high text-14 mb-10">Option Type</div>
      <SwatchesDropdown :index="index" type="rectangle_list" @type-change="handleChangeType" />
    </div>

    <div class="flex-grow">
      <div class="text-light-high text-14 mb-10">Preview Option Values</div>
      <div class="flex gap-8">
        <div
          v-for="item in sampleRectangleListData"
          :key="item.label"
          class="rounded-medium text-14 flex h-40 min-w-[40px] cursor-default items-center justify-center border border-solid bg-white py-4 px-8">
          {{ item.label }}
        </div>
      </div>
      <div class="text-12 text-light-disabled mt-16">Option values will be displayed as text labels</div>
    </div>
  </div>
</template>
