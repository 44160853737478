import { cloneDeepObject } from '@/utils/common';

const useEmitToFrame = () => {
  const refIframe = ref(document.querySelector<HTMLIFrameElement>('.iframe'));
  const refWinContent = ref(refIframe.value?.contentWindow);

  const waitForElement = (doc?: Document, selector?: string, cb?: (element: any) => void, cbf?: () => void) => {
    if (!selector) return;
    const element = refWinContent.value?.document?.querySelector(selector);

    if (!element) {
      cbf?.();
      setTimeout(() => waitForElement(doc, selector, cb, cbf), 500);
      return;
    }

    cb?.(element);
  };

  const waitForElements = (doc?: Document, selector?: string, cb?: (element: any) => void, cbf?: () => void) => {
    if (!selector) return;
    const elements = refWinContent.value?.document?.querySelectorAll(selector);

    if (elements?.length === 0) {
      cbf?.();
      setTimeout(() => waitForElements(doc, selector, cb, cbf), 500);
      return;
    }

    cb?.(elements);
  };

  const emitEventToToolbox = (eventName: string, data: any, callback?: () => void) => {
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: typeof data === 'object' ? cloneDeepObject(data) : data,
    });

    waitForElement(
      refWinContent.value?.document,
      '.builder',
      () => {
        refWinContent.value?.dispatchEvent(event);
        if (callback) {
          callback();
        }
      },
      () => {
        refIframe.value = document.querySelector<HTMLIFrameElement>('.iframe');
        refWinContent.value = refIframe.value?.contentWindow;
      },
    );
  };

  const documentSelector = (selector: string, callback?: (target: Window | null | undefined | HTMLElement) => void) => {
    waitForElement(
      refWinContent.value?.document,
      selector,
      (target) => {
        if (callback) {
          callback(target);
        }
      },
      () => {
        refIframe.value = document.querySelector<HTMLIFrameElement>('.iframe');
        refWinContent.value = refIframe.value?.contentWindow;
      },
    );
  };

  const documentSelectorAll = (selector: string, callback?: (target: HTMLElement[]) => void) => {
    waitForElements(
      refWinContent.value?.document,
      selector,
      (target) => {
        if (callback) {
          callback(target);
        }
      },
      () => {
        refIframe.value = document.querySelector<HTMLIFrameElement>('.iframe');
        refWinContent.value = refIframe.value?.contentWindow;
      },
    );
  };

  return {
    emitEventToToolbox,
    documentSelector,
    documentSelectorAll,
  };
};

export { useEmitToFrame as default };
