<script lang="ts" setup>
import { ref } from 'vue';
import SwatchesDropdown from './components/SwatchesDropdown.vue';
import SwatchesItemColor from './components/SwatchesItemColor.vue';
import type { SwatchesOptionType, SwatchesOptionValue } from './types';

const emit = defineEmits<{
  (e: 'changeData', value: SwatchesOptionValue[], index: string): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = defineProps<{
  index: string;
  data?: SwatchesOptionValue[];
}>();

const MAX_SWATCHES_COLORS = 3;

const initialSwatchData = () => {
  try {
    const data = JSON.parse(JSON.stringify(props.data || []));
    return data;
  } catch (error) {
    console.warn(error);
  }
};
const dataClone = ref<SwatchesOptionValue[]>(initialSwatchData());
const indexSelected = ref(0);

const handleDeleteItem = (index: number) => {
  dataClone.value = dataClone.value?.filter((v, i) => i !== index);
  change();
};

const handleAppendItem = (event: Event) => {
  const label = (event.target as HTMLInputElement).value;

  dataClone.value = [...dataClone.value, { label: label, colors: [] }];
  change();
};

const handleChangeColor = (index: number, position: number, value?: string) => {
  if (value) {
    if (dataClone.value?.[index]?.colors && !dataClone.value?.[index]?.colors?.[position]) {
      dataClone.value[index].colors = [...(dataClone.value?.[index]?.colors ?? []), value];
    } else {
      dataClone.value[index].colors = dataClone.value?.[index]?.colors?.map((v, i) => {
        if (i === position) return value;
        return v;
      });
    }
  }

  change();
};

const handleDeleteColor = (index: number, position: number) => {
  dataClone.value[index].colors = dataClone.value?.[index]?.colors?.filter((v, i) => i !== position);
  change();
};

const handleChangeInput = (e: string) => {
  dataClone.value[indexSelected.value].label = e;
  change();
};

const getIndexInput = (index: number) => {
  indexSelected.value = index;
};

const change = () => {
  emit('changeData', dataClone.value, props.index);
};

const handleChangeType = (type?: SwatchesOptionType) => {
  if (type) {
    emit('typeChange', +props.index, type);
  }
};
</script>

<template>
  <div class="bg-light-400 flex gap-44 p-20">
    <div class="flex flex-col">
      <div class="text-light-high text-14 mb-10">Option Type</div>
      <SwatchesDropdown :index="index" type="color" @type-change="handleChangeType" />
    </div>
    <div class="flex-grow">
      <div class="text-light-high text-14 mb-10">Option Values</div>
      <div v-for="(item, i) in dataClone" :key="i" class="mb-16 flex items-center gap-24">
        <div class="relative h-40 w-[320px]">
          <g-input
            :value="item.label"
            class="absolute inset-0"
            classes="pr-[135px] border-light-100"
            @click="getIndexInput(i)"
            @on-change="handleChangeInput" />
          <div class="absolute top-4 right-4 flex gap-12">
            <div v-for="position in MAX_SWATCHES_COLORS" :key="position" class="relative h-32 w-32">
              <SwatchesItemColor
                :index="i"
                :position="position - 1"
                :color="item.colors?.[position - 1]"
                :is-color="position <= (item.colors?.length ?? 0) + 1"
                @delete-color="handleDeleteColor"
                @change-color="handleChangeColor"></SwatchesItemColor>
            </div>
          </div>
        </div>

        <div class="grid h-32 w-32 grid-flow-col">
          <div v-for="color in item.colors" :key="color" :style="{ 'background-color': color }"></div>
        </div>

        <g-button type="ghost" size="medium" button-classes="!px-8" @click="handleDeleteItem(i)">
          <span class="text-light-high gap-8">
            <g-base-icon name="trash" width="16px" height="16px" view-box="0 0 16 16"></g-base-icon>
          </span>
        </g-button>
      </div>
      <g-input
        placeholder="+ Add more"
        classes="pr-[135px] border-light-100"
        class="border-light-100 w-[320px] outline-none"
        @keyup.enter="handleAppendItem" />
    </div>
  </div>
</template>
