<template>
  <button
    type="button"
    role="button"
    aria-pressed="false"
    class="gt_text_format_right item"
    @click="setFormat('.gt_text_format_right')">
    <svg class="fr-svg" focusable="false" width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M15,18h6v-2h-6V18z M9,11v2h12v-2H9z M3,6v2h18V6H3z" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'FormatTextRight',
  emits: ['statusFormatText'],
  methods: {
    setFormat(classButton) {
      const $parrantButton = event.target.closest(classButton);
      this.$emit('statusFormatText', {
        type: 'justifyRight',
        classButton: $parrantButton,
      });
    },
  },
};
</script>
