import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ThemeStyleSelectFragmentDoc } from '../fragments/theme-style.generated';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ThemeStyleUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  input: Types.UpdateThemeStyleInput;
}>;

export type ThemeStyleUpdateMutationResponse = {
  themeStyleUpdate: Pick<Types.ThemeStyle, 'createdAt' | 'data' | 'default' | 'id' | 'name' | 'updatedAt'>;
};

export const ThemeStyleUpdateDocument = `
    mutation themeStyleUpdate($id: ID!, $input: UpdateThemeStyleInput!) {
  themeStyleUpdate(id: $id, input: $input) {
    ...ThemeStyleSelect
  }
}
    ${ThemeStyleSelectFragmentDoc}`;
export const useThemeStyleUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<ThemeStyleUpdateMutationResponse, TError, ThemeStyleUpdateMutationVariables, TContext>,
) =>
  useMutation<ThemeStyleUpdateMutationResponse, TError, ThemeStyleUpdateMutationVariables, TContext>(
    ['themeStyleUpdate'],
    (
      variables:
        | ThemeStyleUpdateMutationVariables
        | ComputedRef<ThemeStyleUpdateMutationVariables>
        | Ref<ThemeStyleUpdateMutationVariables>,
    ) =>
      appFetcher<ThemeStyleUpdateMutationResponse, ThemeStyleUpdateMutationVariables>(
        ThemeStyleUpdateDocument,
        variables,
      )(),
    options,
  );
useThemeStyleUpdateMutation.getKey = () => ['themeStyleUpdate'];
