import type { ColorProps, CustomColorType } from '@/types/global-style';
import useCurrentGlobalStyle from '../../sidebar/hooks/useCurrentGlobalStyle';

const convertObjectToList = (obj: Partial<Record<CustomColorType, string>>): ColorProps[] => {
  const colors: ColorProps[] = [];
  Object.assign(
    colors,
    Object.entries(obj).map(([key, value]) => {
      if ((key as keyof CustomColorType) && typeof value !== 'object') {
        return {
          colorType: key as CustomColorType,
          color: value,
        };
      }
      return {};
    }),
  );
  return colors.filter((col) => !!col.colorType).concat({ colorType: 'transparent', color: 'transparent' });
};

const useCurrentGlobalColors = (): ColorProps[] => {
  const globalStyle = useCurrentGlobalStyle();
  const globalStyleColors = globalStyle?.value?.color || {};

  return convertObjectToList(globalStyleColors);
};

export default useCurrentGlobalColors;
