<script lang="ts" setup>
import { computed, ref } from 'vue';
import GridItem from './GridItem.vue';
import { arrange, getPositionValue, sumArr } from './helpers';
import { watch } from 'vue';

const props = withDefaults(
  defineProps<{
    cols?: number[];
  }>(),
  {
    cols: () => [],
  },
);

const columns = ref(props.cols);

const emit = defineEmits<{
  (e: 'update-col', value: number[]): void;
  (e: 'on-update-col', value: number[]): void;
}>();

watch(
  () => props.cols,
  (newVal) => {
    columns.value = newVal;
  },
);

const mainArea = ref<HTMLDivElement>();

const gridTemplateColumns = computed(() => {
  if (!columns.value) return '1fr';
  return columns.value.map((item, index, arr) => (index === arr.length - 1 ? `${item}fr` : `${item}fr 4px`)).join(' ');
});

// [1,1,5,5];
const onChangeCols = (index: number, position: number) => {
  const { min, max } = getPositionValue(columns.value, index);
  const currentPosition = sumArr(columns.value, index);
  if (currentPosition === position || position < min || position > max) return;
  const newCols = arrange(columns.value, index, position);
  columns.value = newCols;
  emit('on-update-col', newCols);
};

const changeCols = () => {
  emit('update-col', columns.value);
};
</script>

<template>
  <div
    ref="mainArea"
    :draggable="false"
    class="bg-dark-400 rounded-medium grid h-36 p-4 text-white"
    :style="{
      gridTemplateColumns: gridTemplateColumns,
    }">
    <GridItem
      v-for="(item, index) in columns"
      :key="index"
      :cols="columns"
      :area="mainArea"
      :value="item"
      :index="index"
      :total-col="columns.length"
      @update-position="changeCols"
      @on-update-position="onChangeCols">
    </GridItem>
  </div>
</template>
