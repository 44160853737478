import paddingTop from './padding-top.vue';
import paddingLeft from './padding-left.vue';
import paddingBottom from './padding-bottom.vue';
import paddingRight from './padding-right.vue';
import marginTop from './margin-top.vue';
import marginLeft from './margin-left.vue';
import marginBottom from './margin-bottom.vue';
import marginRight from './margin-right.vue';

export const icons = {
  'padding-top': paddingTop,
  'padding-left': paddingLeft,
  'padding-bottom': paddingBottom,
  'padding-right': paddingRight,
  'margin-top': marginTop,
  'margin-left': marginLeft,
  'margin-bottom': marginBottom,
  'margin-right': marginRight,
};
