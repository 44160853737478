<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import type { File as ImageFile } from '../../type/graphql';
import SwatchesDropdown from './components/SwatchesDropdown.vue';
import SwatchesGallery from './components/SwatchesGallery.vue';
import type { SwatchesOptionType, SwatchesOptionValue } from './types';

const emit = defineEmits<{
  (e: 'changeData', value: SwatchesOptionValue[], index: string): void;
  (e: 'uploadImage', formData: FormData, index?: string, toVariant?: string): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = withDefaults(
  defineProps<{
    index: string;
    data?: SwatchesOptionValue[];
    images?: ImageFile[];
    maximumSize?: number;
    allowedFiles?: string[];
    shopId: string;
  }>(),
  {
    allowedFiles: () => ['image/avif', 'image/jpeg', 'image/tiff', 'image/bmp', 'image/gif', 'image/png', 'image/webp'],
    maximumSize: 2 * 1024 * 1024, // 2MB
  },
);

const initialSwatchImageData = () => {
  try {
    const data = JSON.parse(JSON.stringify(props.data || []));
    return data;
  } catch (error) {
    console.warn(error);
  }
};

const data = computed(() => props.data);
const dataClone = ref<SwatchesOptionValue[]>(initialSwatchImageData());
const indexSelected = ref(0);
const isGallery = ref(false);

const handleChangeInput = (e: string) => {
  dataClone.value[indexSelected.value].label = e;
  change();
};

const handleAppendItem = (event: Event) => {
  const label = (event.target as HTMLInputElement).value;
  dataClone.value = [...dataClone.value, { label: label, imageUrl: '' }];
  change();
};

const handleDeleteItem = (index: number) => {
  dataClone.value = dataClone.value.filter((v, i) => i !== index);
  change();
};

const handleOpenGallery = () => {
  isGallery.value = true;
};

const closeGallery = () => {
  isGallery.value = false;
};

const handleChangeFile = (event: Event, toVariant: string) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;
  if (!props.allowedFiles.includes(file.type) || file.size > props.maximumSize) {
    return;
  }

  postUploadImage(file, toVariant);
};

const postUploadImage = async (_urlImage: File, toVariant: string) => {
  const formData = new FormData();
  formData.append('file', _urlImage);
  formData.append('shopID', props.shopId);
  formData.append('appName', 'gemx');

  if (props.index) emit('uploadImage', formData, props.index, toVariant);
};

const handleUploadImage = (file: File) => {
  if (props.index) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('shopID', props.shopId);
    formData.append('appName', 'gemx');
    emit('uploadImage', formData, props.index);
  }
};

const handleChangeType = (type?: SwatchesOptionType) => {
  if (type) {
    emit('typeChange', Number(props.index), type);
  }
};

const handleDeleteImage = (index: number) => {
  dataClone.value = dataClone.value.map((v, i) => {
    if (i === index) {
      return {
        ...v,
        imageUrl: '',
      };
    }
    return v;
  });

  change();
};

const change = () => {
  if (props.index) emit('changeData', dataClone.value, props.index);
};

watch(
  () => props.data,
  () => {
    dataClone.value = initialSwatchImageData();
  },
);
</script>

<template>
  <div class="bg-light-400 flex gap-44 p-20">
    <div class="flex flex-col">
      <div class="text-light-high text-14 mb-10">Option Type</div>
      <SwatchesDropdown :index="index" type="image" @type-change="handleChangeType" />
    </div>

    <div class="flex-grow">
      <div class="text-light-high text-14 mb-10">Option Values</div>

      <div v-for="(item, i) in data" :key="i" class="mb-40">
        <div class="flex items-center gap-36">
          <g-input
            :value="item.label"
            class="w-[280px] outline-none"
            classes="border-light-100 h-44"
            @on-change="handleChangeInput"
            @click="indexSelected = i" />
          <div class="relative flex flex-col items-center">
            <div class="relative h-44 w-44 cursor-pointer">
              <input
                class="!border-light-100 absolute h-full w-full cursor-pointer border opacity-0"
                type="file"
                accept="image/*"
                @change.stop="handleChangeFile($event, i.toString())" />
              <img
                class="h-full w-full !cursor-pointer overflow-hidden object-cover"
                :src="item.imageUrl ? item.imageUrl : 'https://ucarecdn.com/1a741e5e-1333-4bbb-8bac-d10d017554c3/'"
                alt="image" />
              <g-base-icon
                v-if="item.imageUrl"
                name="circle-close"
                class="absolute top-[-6px] right-[-6px] text-red-500"
                viewBox="0 0 512 512"
                width="12"
                height="12"
                @click.stop="handleDeleteImage(i)">
              </g-base-icon>
            </div>
            <div
              class="text-12 text-primary-325 absolute -bottom-24 cursor-pointer whitespace-nowrap"
              @click="handleOpenGallery">
              Browser Gallery
            </div>
          </div>
          <g-button type="ghost" size="medium" button-classes="!px-8" @click="handleDeleteItem(i)">
            <span class="text-light-high gap-8">
              <g-base-icon name="trash" width="16px" height="16px" view-box="0 0 16 16"></g-base-icon>
            </span>
          </g-button>
        </div>
      </div>

      <g-input
        placeholder="+ Add more"
        class="w-[280px] outline-none"
        classes="pr-[135px] border-light-100"
        @keyup.enter.stop="handleAppendItem" />

      <SwatchesGallery
        v-if="isGallery"
        :images="images"
        :maximum-size="maximumSize"
        :allowed-files="allowedFiles"
        @upload-image="handleUploadImage"
        @close="closeGallery" />
    </div>
  </div>
</template>
