<script lang="ts" setup>
import SwatchesDropdown from './components/SwatchesDropdown.vue';
import type { SwatchesOptionType, SwatchesOptionValue } from './types';

const emit = defineEmits<{
  (e: 'change', value: SwatchesOptionValue[]): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = defineProps<{
  index: string;
}>();

const handleChangeType = (type?: SwatchesOptionType) => {
  if (type) {
    emit('typeChange', +props.index, type);
  }
};
</script>

<template>
  <div class="bg-light-400 flex gap-44 p-20">
    <div class="flex flex-col">
      <div class="text-light-high text-14 mb-10">Option Type</div>
      <SwatchesDropdown :index="index" type="image_shopify" @type-change="handleChangeType" />
    </div>

    <div class="flex-grow">
      <div class="text-light-high text-14 mb-10">Preview Option Values</div>
      <div class="flex gap-8">
        <div v-for="item in 5" :key="item">
          <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="31" height="29" rx="2.5" fill="#525252" />
            <path
              d="M8 9.89215L13.328 8.0308C13.328 8.0308 13.9759 9.54863 16.0053 9.54863C18.0348 9.54863 18.6755 8 18.6755 8L24 9.89215L23.1138 14.5595H20.4418V23H11.5467V14.5567H8.888L8 9.89215Z"
              fill="#F9F9F9" />
            <rect x="0.5" y="0.5" width="31" height="29" rx="2.5" stroke="#5B5B5B" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
