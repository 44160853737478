export const getLoginAsToken = () => {
  return localStorage.getItem('login-as-token');
};

export const getLoginAsShopId = () => {
  return localStorage.getItem('login-as-shop-id');
};

export const getLoginAsShopDomain = () => {
  return localStorage.getItem('login-as-shop-domain');
};

export const clearLoginAs = () => {
  localStorage.removeItem('login-as-token');
  localStorage.removeItem('login-as-shop-id');
  localStorage.removeItem('login-as-shop-domain');
};

export const setLoginAs = ({ token, shopId, shopDomain }: { token: string; shopId: string; shopDomain: string }) => {
  localStorage.setItem('login-as-token', token);
  localStorage.setItem('login-as-shop-id', shopId);
  localStorage.setItem('login-as-shop-domain', shopDomain);
};
