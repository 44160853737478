<script lang="ts" setup>
import type { ColorProps } from '../global-color/types';
import BorderMoreSetting from './BorderMoreSetting.vue';
import type { BorderType, CommonBorder } from './types';
import NumberUnit from '../input/NumberUnit.vue';

type Props = {
  id: string;
  value?: BorderType;
  adv?: boolean;
  globalColors?: ColorProps[];
  colorMapping?: Record<string, string>;
  options?: CommonBorder[];
  emptyOnClear?: boolean;
};

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string, value?: BorderType): void;
  (e: 'controlOnChange', controlId?: string, value?: BorderType): void;
}>();

const props = defineProps<Props>();

const change = (data: BorderType, type: 'controlOnChange' | 'controlChange') => {
  data.width = generateBorderWidthStyle(data.borderWidth || '0', data.position || 'all');
  data.isCustom = true;
  if (type === 'controlChange') emit('controlChange', props.id, data);
  else emit('controlOnChange', props.id, data);
};
const updateBorderWidth = (value: string, type: 'controlOnChange' | 'controlChange') => {
  change(
    {
      ...props.value,
      borderWidth: value,
      border: props.value?.border === 'none' || !props.value?.border ? 'solid' : props.value?.border,
    },
    type,
  );
};

const handleChangeBorderWidth = (value: string) => {
  updateBorderWidth(value, 'controlChange');
};

const handleOnChangeBorderWidth = (value: string) => {
  updateBorderWidth(value, 'controlOnChange');
};

const handleChangeBorderMoreSetting = (data: BorderType) => {
  change(data, 'controlChange');
};

const handleOnChangeBorderMoreSetting = (data: BorderType) => {
  change(data, 'controlOnChange');
};

const generateBorderWidthStyle = (width: string, position: string) => {
  width = width.replace('px', '');
  switch (position) {
    case 'all':
      return `${width}px ${width}px ${width}px ${width}px`;
    case 'top':
      return `${width}px 0px 0px 0px`;
    case 'right':
      return `0px ${width}px 0px 0px`;
    case 'bottom':
      return `0px 0px ${width}px 0px`;
    case 'left':
      return `0px 0px 0px ${width}px`;
  }
};
</script>

<template>
  <div class="flex w-full justify-end">
    <div class="flex h-36 justify-end">
      <NumberUnit
        :value="value?.borderWidth"
        :min="0"
        :convert-null-to-min="true"
        :use-only-unit-init="true"
        :units="['px']"
        :hide-unit="false"
        :empty-on-clear="emptyOnClear"
        placeholder="Auto"
        class="mr-8 !w-[56px]"
        input-type="number"
        @change="handleChangeBorderWidth"
        @on-change="handleOnChangeBorderWidth" />
      <BorderMoreSetting
        :id="id"
        :value="value"
        :global-colors="globalColors"
        :color-mapping="colorMapping"
        @control-change="handleChangeBorderMoreSetting"
        @control-on-change="handleOnChangeBorderMoreSetting" />
    </div>
  </div>
</template>
