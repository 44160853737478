import type { GlobalSwatchesData } from '@/types/global-data';
import { defineStore } from 'pinia';

export type State = {
  data: GlobalSwatchesData[];
};

export const useSwatchesStore = defineStore('swatches', {
  state: (): State => ({
    data: [],
  }),
  getters: {
    getDataSwatches(state) {
      return state.data;
    },
  },
  actions: {
    setDataSwatches(data: GlobalSwatchesData[]) {
      this.data = data;
    },
  },
});

export default useSwatchesStore;
