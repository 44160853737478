<script lang="ts" setup>
import { GReorder } from '@gem/uikit';
import { computed } from 'vue';
import { getResponsiveValueByScreen } from '../../helpers/common';
import type { GroupTypeSetting, ScreenType } from '../../types';
import DeviceRecommend from './components/DeviceRecommend.vue';
import DeviceProductRecommend from './components/DeviceProductRecommend.vue';
import GridArrange from './components/GridArrange.vue';

type ActionSettingInput = {
  groupType: GroupTypeSetting;
  componentUid: string;
  controlType: string;
  controlId: string;
  newValue: any;
  screenId: ScreenType;
  hasDevices?: boolean;
};

type GridSettingInput = Pick<ActionSettingInput, 'newValue' | 'screenId'>;

type ChildItem = {
  uid: string;
  tag: string;
  label?: string;
  settings?: Record<string, any>;
  styles?: Record<string, any>;
  advanced?: Record<string, any>;
  [key: string]: any;
};

type LayoutValue = {
  display?: string;
  cols?: number[];
};

type Props = {
  id: string;
  controlType: string;
  currentScreen?: ScreenType;
  value?: LayoutValue;
  childrens?: ChildItem[];
  maxCol?: number;
  isProductLayout?: boolean
  devices?: {
    desktop?: {
      default?: LayoutValue;
    };
    tablet?: {
      default?: LayoutValue;
    };
    mobile?: {
      default?: LayoutValue;
    };
  };
};
const props = withDefaults(defineProps<Props>(), {
  maxCol: 6,
  currentScreen: 'desktop',
});

const displayOptions = [
  {
    label: 'Fit to content',
    value: 'fit',
  },
  {
    label: 'By ratio',
    value: 'fill',
  },
];

const emit = defineEmits<{
  (e: 'control-change', id: Props['id'], value: Props['value'] | undefined, screenId?: ScreenType): void;
  (e: 'control-on-change', id: Props['id'], value: Props['value'] | undefined, screenId?: ScreenType): void;
  (e: 'control-children-change', input: ActionSettingInput[]): void;
  (e: 'layout-change', input: GridSettingInput[]): void;
  (e: 'remove-item', uid: string): void;
  (e: 'copy-item', uid: string): void;
  (e: 'add-item'): void;
}>();

// const desktopLayout = computed(() => {
//   return props.devices?.desktop?.default;
// });

const items = computed(() => {
  return [...(props.childrens ?? [])].sort((a, b) => {
    const order1 = getResponsiveValueByScreen(a.styles?.order, props.currentScreen) ?? 0;
    const order2 = getResponsiveValueByScreen(b.styles?.order, props.currentScreen) ?? 0;
    if (order1 !== order2) {
      return Number(order1) - Number(order2);
    }
    return 0;
  });
});

const orderItems = computed(() => {
  return items.value.map((v) => ({
    id: v.uid,
    title: 'Column',
  }));
});

const maxColumn = computed(() => {
  return props.currentScreen === 'desktop' ? props.maxCol : orderItems.value.length ?? 6;
});

const handleChangeLayout = (totalCol: number) => {
  if (props.value?.cols?.length === totalCol || !totalCol) return;
  if (props.currentScreen === 'desktop') {
    const input: GridSettingInput[] = [
      {
        newValue: {
          ...props.value,
          cols: Array.from({ length: totalCol }, () => 12 / totalCol),
        },
        screenId: 'desktop',
      },
      { newValue: undefined, screenId: 'tablet' },
      {
        newValue: {
          cols: [12],
        },
        screenId: 'mobile',
      },
    ];

    emit('layout-change', input);
  } else {
    emit('control-change', props.id, {
      ...props.value,
      cols: Array.from({ length: totalCol }, () => 12 / totalCol),
    });
  }
};

const displayValue = computed(() => {
  return props.value?.display ?? 'fill';
});

const handleChangeDisplay = (type: string) => {
  if (displayValue.value === type) return;
  emit('control-change', props.id, {
    ...props.value,
    display: type,
  });
};

const handleOnChangeCol = (cols: number[]) => {
  emit('control-on-change', props.id, {
    ...props.value,
    cols,
  });
};
const handleChangeCol = (cols: number[]) => {
  emit('control-change', props.id, {
    ...props.value,
    cols,
  });
};

const getPosition = (id: string) => {
  return (props.childrens?.findIndex((item) => item.uid === id) ?? 0) + 1;
};

const onReOrder = (data: { id?: string; title?: string }[]) => {
  const input: ActionSettingInput[] = (data.filter((i) => !!i.id) as { id: string; title?: string }[]).map(
    (item, index) => {
      return {
        groupType: 'style',
        componentUid: item.id,
        controlType: 'input:number',
        controlId: 'order',
        newValue: index,
        screenId: props.currentScreen,
        hasDevices: true,
      };
    },
  );
  emit('control-children-change', input);
};

const handleRemoveItem = (uid: string) => {
  emit('remove-item', uid);
};

const handleCopyItem = (uid: string) => {
  emit('copy-item', uid);
};

const canCopy = computed(() => {
  return props.currentScreen === 'desktop' && items.value.length < 6;
});
const canDelete = computed(() => {
  return props.currentScreen === 'desktop' && items.value.length > 1;
});
</script>

<template>
  <div class="w-full">
    <slot name="label"></slot>
    <div class="relative flex w-full flex-col gap-16">
      <DeviceProductRecommend v-if="isProductLayout" :value="value" :max-col="2" @change="handleChangeLayout" />
      <DeviceRecommend
        v-else
        :value="value"
        :device="currentScreen"
        :max-col="maxColumn"
        @change="handleChangeLayout" />
      <div>
        <p class="text-12 text-dark-low font-regular h-36 leading-[36px]">Column width</p>
        <div class="bg-dark-500 rounded-medium flex w-full flex-col justify-center gap-4">
          <div
            v-for="item in displayOptions"
            :key="item.value"
            class="text-12 text-dark-high font-regular rounded-medium flex h-28 cursor-pointer select-none items-center gap-4 text-center"
            @click.stop="handleChangeDisplay(item.value)">
            <div
              class="flex h-16 w-16 items-center justify-center rounded-full border-[1.25px]"
              :class="{
                'border-primary-300 before:bg-primary-300 before:h-8 before:w-8 before:rounded-full':
                  displayValue === item.value,
                'border-dark-low': displayValue !== item.value,
              }"></div>
            <span>{{ item.label }}</span>
          </div>
        </div>
        <div v-if="displayValue === 'fill'" class="mt-8">
          <GridArrange :cols="value?.cols" @update-col="handleChangeCol" @on-update-col="handleOnChangeCol" />
        </div>
      </div>

      <div class="text-dark-high">
        <div class="flex h-36 items-center">
          <span class="text-12 text-dark-low">Column</span>
        </div>
        <div class="flex flex-col gap-8">
          <GReorder :items="orderItems" @re-order="onReOrder">
            <template #default="{ item }">
              <div
                class="bg-dark-400 text-dark-high group-[.sortable-fallback]/sortable-item:bg-dark-400 text-12 rounded-medium my-4 flex h-36 items-center pl-10 font-medium transition-all duration-200 hover:cursor-move group-[.sortable-ghost]/sortable-item:bg-neutral-700 group-[&:not(.dragging)]/sortable:hover:bg-neutral-700">
                <div class="mr-4">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 4.5C6.32843 4.5 7 3.82843 7 3C7 2.17157 6.32843 1.5 5.5 1.5C4.67157 1.5 4 2.17157 4 3C4 3.82843 4.67157 4.5 5.5 4.5Z"
                      fill="currentColor" />
                    <path
                      d="M5.5 9.5C6.32843 9.5 7 8.82843 7 8C7 7.17157 6.32843 6.5 5.5 6.5C4.67157 6.5 4 7.17157 4 8C4 8.82843 4.67157 9.5 5.5 9.5Z"
                      fill="currentColor" />
                    <path
                      d="M7 13C7 13.8284 6.32843 14.5 5.5 14.5C4.67157 14.5 4 13.8284 4 13C4 12.1716 4.67157 11.5 5.5 11.5C6.32843 11.5 7 12.1716 7 13Z"
                      fill="currentColor" />
                    <path
                      d="M10.5 4.5C11.3284 4.5 12 3.82843 12 3C12 2.17157 11.3284 1.5 10.5 1.5C9.67157 1.5 9 2.17157 9 3C9 3.82843 9.67157 4.5 10.5 4.5Z"
                      fill="currentColor" />
                    <path
                      d="M12 8C12 8.82843 11.3284 9.5 10.5 9.5C9.67157 9.5 9 8.82843 9 8C9 7.17157 9.67157 6.5 10.5 6.5C11.3284 6.5 12 7.17157 12 8Z"
                      fill="currentColor" />
                    <path
                      d="M10.5 14.5C11.3284 14.5 12 13.8284 12 13C12 12.1716 11.3284 11.5 10.5 11.5C9.67157 11.5 9 12.1716 9 13C9 13.8284 9.67157 14.5 10.5 14.5Z"
                      fill="currentColor" />
                  </svg>
                </div>
                <div class="flex gap-4">
                  <span>{{ item.title }} {{ getPosition(item.id) }}</span>
                </div>
                <div class="ml-auto inline-flex h-full cursor-default items-center gap-8 pr-10">
                  <button
                    v-if="canCopy"
                    class="flex h-24 w-24 items-center justify-center"
                    @click.prevent="handleCopyItem(item.id)">
                    <g-base-icon width="16px" name="copy" view-box="0 0 16 16"></g-base-icon>
                  </button>
                  <button
                    :disabled="!canDelete"
                    class="flex h-24 w-24 items-center justify-center"
                    :class="{ 'text-dark-disabled cursor-no-drop': !canDelete }"
                    @click.prevent="handleRemoveItem(item.id)">
                    <g-base-icon width="16px" name="trash" view-box="0 0 16 16"></g-base-icon>
                  </button>
                </div>
              </div>
            </template>
          </GReorder>
        </div>
      </div>
    </div>

    <slot name="info"></slot>
  </div>
</template>
