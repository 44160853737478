<script lang="ts" setup>
import { ref, watch } from 'vue';
import { DatePicker } from 'v-calendar';

const props = defineProps<{
  id: string;
  value: string | null;
  default: string | null;
  format?: string | null;
}>();

const emit = defineEmits<{
  (e: 'controlChange', controlId?: string | number, value?: any): void;
}>();

const attributes = ref([{ bar: { style: { backgroundColor: '#0e60e5' } }, dates: new Date() }]);
const dateTime = ref(props.value ?? props.default ?? new Date());
const format = ref(props.format ?? 'MMMM DD YYYY');

watch(dateTime, () => {
  emit('controlChange', props.id, dateTime.value);
});
</script>

<template>
  <div>
    <slot name="label"></slot>
    <DatePicker
      v-model="dateTime"
      :popover="{ visibility: 'click' }"
      :is-required="true"
      :attributes="attributes"
      :masks="{
        input: 'YYYY-MM-DD',
        L: format,
      }"
      is-dark
      color="blue"
      mode="dateTime">
      <template #default="{ inputValue, inputEvents }">
        <input
          class="rounded-medium text-12 text-dark-high placeholder:text-dark-disabled hover:bg-dark-200 focus:border-primary-300 disabled:border-dark-200 disabled:text-dark-disabled bg-dark-400 h-32 w-full border border-transparent px-8 outline-none transition duration-200 hover:border-transparent disabled:cursor-not-allowed"
          :value="inputValue"
          v-on="inputEvents" />
      </template>
    </DatePicker>
  </div>
</template>
