<script lang="ts" setup>
import { GPopover } from '@gem/uikit';
import { computed } from 'vue';
import JustifyContent from './JustifyContent.vue';
import JustifyContentItem from './JustifyContentItem.vue';

const props = defineProps<{
  value?: string;
  uid: string;
}>();

const emit = defineEmits<{
  (e: 'select', uid: string, value?: string): void;
}>();

const onSelect = (value?: string) => {
  emit('select', props.uid, value);
};

const items = [
  {
    value: 'flex-start',
    label: 'Top',
    className: 'justify-start',
  },
  {
    value: 'flex-end',
    label: 'Bottom',
    className: 'justify-end',
  },
  {
    value: 'center',
    label: 'Center',
    className: 'justify-center',
  },
  {
    value: 'space-between',
    label: 'Between',
    className: 'justify-between',
  },
  {
    value: 'space-around',
    label: 'Around',
    className: 'justify-around',
  },
  {
    value: 'space-evenly',
    label: 'Evenly',
    className: 'justify-evenly',
  },
];
const item = computed(() => items.find((i) => i.value === props.value));
</script>

<template>
  <GPopover
    :overlay="false"
    placement="bottom"
    button-class="ml-auto"
    wrapper-class="z-[9999] cursor-default"
    closeable
    cls="!bg-dark-300"
    arrow-class="!border-r-dark-300"
    strategy="absolute">
    <template #content>
      <div class="flex flex-col items-center gap-8">
        <p>Justify content</p>
        <JustifyContent :value="value" @select="onSelect" />
      </div>
    </template>
    <template #default>
      <button class="rounded-medium bg-dark-300 flex aspect-square w-24 items-center justify-center text-white">
        <JustifyContentItem :class="item?.className" />
      </button>
    </template>
  </GPopover>
</template>
