<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="gemx-checkbox-group">
      <div v-for="opt of options" :key="opt.value" class="gemx-checkbox-item">
        <div class="gemx-checkbox-main">
          <input :id="opt.value" v-model="val" type="checkbox" :value="opt.value" @change.stop="change" />
          <label :for="opt.value">{{ opt.text }}</label>
        </div>
        <p class="gemx-checkbox-desc">{{ opt.desc }}</p>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

type PropsType = {
  id?: string | number;
  value?: any;
  options?: any;
};
const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  value: [],
  options: [
    {
      text: 'Unset',
      desc: '',
      value: 'unset',
    },
    {
      text: 'None',
      desc: '',
      value: 'none',
    },
    {
      text: 'Initial',
      desc: '',
      value: 'initial',
    },
  ],
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

const val = ref(props.value);

const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
  }
};
watch(props.value, (value) => {
  setValue(value);
});

// const onChange = () => {
//   emit('controlOnChange', props.id, val.value);
// };
const change = () => {
  emit('controlChange', props.id, val.value);
};
// const focus = () => {
//   emit('controlFocus', props.id, val.value);
// };
// const blur = () => {
//   emit('controlBlur', props.id, val.value);
// };
</script>

<style lang="postcss" scoped>
.gemx-checkbox-group {
  .gemx-checkbox-item {
    position: relative;
    margin-bottom: 4px;
    cursor: pointer;
    font-size: 1em;
    align-items: center;
    .gemx-checkbox-main {
      display: flex;
      align-items: center;
      cursor: pointer;
      label {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        color: #43484b;
        cursor: pointer;
        margin: 3px 0 0 10px;
        user-select: none;
      }
    }
    .gemx-checkbox-desc {
      font-size: 12px;
      line-height: 18px;
      color: #7b7d81;
    }
  }
}
</style>
