<template>
  <div class="gemx-control gt_control-segment">
    <slot name="label"></slot>
    <div class="gt_control-radio-group">
      <div
        v-for="opt in options"
        :key="opt.value"
        :class="{ active: opt.value === val }"
        :disabled="opt.value === val"
        class="gt_control-radio-item"
        @click="changeValue(opt.value)">
        <div class="gt_control-radio-main">
          <div class="icon-radio" />
          <div class="gt_control-radio-item--txt">
            <label>{{ opt.text }}</label>
          </div>
        </div>
        <p class="gt_control-radio-desc">{{ opt.desc }}</p>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

type PropsType = {
  id?: string | number;
  value?: any;
  options?: {
    text?: string;
    desc?: string;
    value?: string;
  }[];
};
const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  value: '',
  options: () => [
    {
      text: 'Unset',
      desc: '',
      value: 'unset',
    },
    {
      text: 'None',
      desc: '',
      value: 'none',
    },
    {
      text: 'Initial',
      desc: '',
      value: 'initial',
    },
  ],
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

const val = ref('');

const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
  }
};

watch(props.value, (value) => {
  setValue(value);
});

setValue(props.value);

// const onChange = () => {
//   emit('controlOnChange', props.id, val.value);
// };

const change = () => {
  emit('controlChange', props.id, val.value);
};
// const focus = () => {
//   emit('controlFocus', props.id, val.value);
// };
// const blur = () => {
//   emit('controlBlur', props.id, val.value);
// };
const changeValue = (val: any) => {
  if (val != val.value) {
    val.value = val;
    change();
  }
};
</script>

<style lang="scss" scoped>
.gt_control-radio-item {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0px;
  }

  &.active {
    .icon-radio {
      border: 5px solid #3e50af;
    }
  }

  .gt_control-radio-main {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .icon-radio {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    box-sizing: border-box;
    position: relative;
    border-radius: 50%;
    border: 2px solid #c5cbd7;
  }

  .gt_control-radio-item--txt {
    cursor: pointer;
    width: calc(100% - 26px);

    label {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #43484b;
      cursor: pointer;
      margin-bottom: 0;
      user-select: none;
    }
  }
  .gt_control-radio-desc {
    font-size: 12px;
    line-height: 18px;
    color: #7b7d81;
  }
}
</style>
