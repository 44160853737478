<script lang="ts" setup>
import SwatchesDropdown from './components/SwatchesDropdown.vue';
import type { SwatchesOptionType, SwatchesOptionValue } from './types';

const emit = defineEmits<{
  (e: 'change', value: SwatchesOptionValue[]): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = defineProps<{
  index: string;
}>();

const handleChangeType = (type?: SwatchesOptionType) => {
  if (type) {
    emit('typeChange', +props.index, type);
  }
};
const sampleRadioData = [
  { label: 'Extra Small' },
  { label: 'S' },
  { label: 'M' },
  { label: 'L' },
  { label: 'Extra Large' },
];
</script>

<template>
  <div class="bg-light-400 flex gap-44 p-20">
    <div class="flex flex-col">
      <div class="text-light-high text-14 mb-10">Option Type</div>
      <SwatchesDropdown :index="index" type="radio_buttons" @type-change="handleChangeType" />
    </div>

    <div class="flex-grow">
      <div class="text-light-high text-14 mb-10">Preview Option Values</div>
      <div class="mb-8">
        <div v-for="(item, i) in sampleRadioData" :key="item.label" class="mb-4">
          <input
            :id="item.label"
            type="radio"
            :value="item.label"
            name="swatches"
            class="mr-4 cursor-pointer"
            :checked="i === 0" />
          <label :for="item.label" class="cursor-pointer">{{ item.label }}</label>
        </div>
      </div>
      <div class="text-12 text-light-disabled mt-16">Option values will be displayed as radio buttons</div>
    </div>
  </div>
</template>
