<script lang="ts" setup>
const props = defineProps<{
  value: string;
  selected?: boolean;
}>();

const emit = defineEmits<{
  (e: 'change', value?: string): void;
}>();

const onClick = () => {
  emit('change', props.value);
};
</script>

<template>
  <div class="flex h-full w-full cursor-pointer items-center justify-center" @click="onClick">
    <div
      class="aspect-square rounded-full"
      :class="{
        'border-primary-325 w-12 border-[4px] bg-transparent': selected,
        'w-4 bg-white': !selected,
      }"></div>
  </div>
</template>
