<script lang="ts" setup>
import { GReorder } from '@gem/uikit';
import { computed } from 'vue';
import { getResponsiveValueByScreen } from '../../helpers/common';
import { getFactors } from '../../helpers/math';
import type { GroupTypeSetting, ScreenType } from '../../types';
import JustifyButton from './components/justify/JustifyButton.vue';
import DeviceRecommend from './DeviceRecommend.vue';
import { gridToArray } from './hooks';

type ActionSettingInput = {
  groupType: GroupTypeSetting;
  componentUid: string;
  controlType: string;
  controlId: string;
  newValue: any;
  screenId: ScreenType;
  hasDevices?: boolean;
};

type GridSettingInput = Pick<ActionSettingInput, 'newValue' | 'screenId'>;

type ChildItem = {
  uid: string;
  tag: string;
  label?: string;
  settings?: Record<string, any>;
  styles?: Record<string, any>;
  advanced?: Record<string, any>;
  [key: string]: any;
};

type Props = {
  id: string;
  value: string;
  currentScreen: ScreenType;
  maxCol?: number;
  childrens?: ChildItem[];
  mobileOnly?: boolean;
  devices: {
    desktop?: {
      default?: string;
    };
    tablet?: {
      default?: string;
    };
    mobile?: {
      default?: string;
    };
  };
};

const props = withDefaults(defineProps<Props>(), {
  maxCol: 6,
  devices: () => ({
    desktop: {
      default: '1fr',
    },
  }),
});

const emit = defineEmits<{
  (e: 'control-change', id: Props['id'], value: Props['value'] | undefined, screenId?: ScreenType): void;
  (e: 'control-children-change', input: ActionSettingInput[]): void;
  (e: 'layout-change', input: GridSettingInput[]): void;
  // (e: 'remove-item', uid: string): void;
  (e: 'add-item'): void;
}>();

const desktopLayout = computed(() => {
  return gridToArray(props.devices.desktop?.default);
});
const tabletLayout = computed(() => {
  return gridToArray(props.devices.tablet?.default);
});
const mobileLayout = computed(() => {
  return gridToArray(props.devices.mobile?.default);
});

const items = computed(() => {
  return [...(props.childrens ?? [])].sort((a, b) => {
    const order1 = getResponsiveValueByScreen(a.styles?.order, props.currentScreen) ?? 0;
    const order2 = getResponsiveValueByScreen(b.styles?.order, props.currentScreen) ?? 0;
    if (order1 !== order2) {
      return Number(order1) - Number(order2);
    }
    return 0;
  });
});

const orderItems = computed(() => {
  return items.value.map((v) => ({
    id: v.uid,
    title: 'Column',
  }));
});

const justifyContentMap = computed(() => {
  return (
    props.childrens?.reduce((acc, item) => {
      const justify: string | undefined = getResponsiveValueByScreen(item.styles?.justifyContent, props.currentScreen);
      if (justify) {
        acc[item.uid] = justify;
      }
      return acc;
    }, {} as Record<string, string>) ?? {}
  );
});

const handleChangeLayout = (layout?: string) => {
  if (props.currentScreen === 'desktop') {
    if (!layout) {
      return;
    }
    const input: GridSettingInput[] = [
      {
        newValue: layout,
        screenId: 'desktop',
      },
    ];
    if (!props.mobileOnly) {
      const cols = gridToArray(layout);
      const factors = getFactors(cols.length);
      if (tabletLayout.value && !factors.includes(tabletLayout.value.length)) {
        input.push({
          newValue: undefined,
          screenId: 'tablet',
        });
      }
      if (mobileLayout.value && !factors.includes(mobileLayout.value.length)) {
        input.push({
          newValue: 'minmax(0, 1fr)',
          screenId: 'mobile',
        });
      }
    } else {
      input.push({
        newValue: undefined,
        screenId: 'tablet',
      });
      input.push({
        newValue: undefined,
        screenId: 'mobile',
      });
    }
    emit('layout-change', input);
  } else {
    emit('control-change', props.id, layout);
  }
};

// const onRemoveColumn = (uid: string) => {
//   const index = orderItems.value.findIndex((v) => v.id === uid);

//   const arrLayoutDesktop = gridToArray(props.devices.desktop?.default);
//   const arrLayoutTablet = gridToArray(props.devices.tablet?.default);
//   const arrLayoutMobile = gridToArray(props.devices.mobile?.default);
//   const input: GridSettingInput[] = [];

//   if (arrLayoutDesktop.length === items.value.length) {
//     input.push({
//       newValue: arrLayoutDesktop.filter((v, i) => i !== index).join(' '),
//       screenId: 'desktop',
//     });
//   }
//   if (arrLayoutTablet.length === items.value.length) {
//     input.push({
//       newValue: arrLayoutTablet.filter((v, i) => i !== index).join(' '),
//       screenId: 'tablet',
//     });
//   }
//   if (arrLayoutMobile.length === items.value.length) {
//     input.push({
//       newValue: arrLayoutMobile.filter((v, i) => i !== index).join(' '),
//       screenId: 'mobile',
//     });
//   }
//   emit('remove-item', uid);
//   emit('layout-change', input);
// };

const getPosition = (id: string) => {
  return (props.childrens?.findIndex((item) => item.uid === id) ?? 0) + 1;
};

const onReOrder = (data: { id?: string; title?: string }[]) => {
  const input: ActionSettingInput[] = (data.filter((i) => !!i.id) as { id: string; title?: string }[]).map(
    (item, index) => {
      return {
        groupType: 'style',
        componentUid: item.id,
        controlType: 'input',
        controlId: 'order',
        newValue: index,
        screenId: props.currentScreen,
        hasDevices: true,
      };
    },
  );
  emit('control-children-change', input);
};

const onJustifySelect = (uid: string, value?: string) => {
  const input: ActionSettingInput = {
    groupType: 'style',
    componentUid: uid,
    controlType: 'input',
    controlId: 'justifyContent',
    newValue: value,
    screenId: props.currentScreen,
    hasDevices: true,
  };
  emit('control-children-change', [input]);
};

const maxColumn = computed(() => {
  return props.currentScreen === 'desktop' || props.mobileOnly ? props.maxCol : desktopLayout.value?.length;
});

const currentDevice = computed(() => {
  return props.mobileOnly ? 'desktop' : props.currentScreen;
});
</script>

<template>
  <div class="w-full">
    <slot name="label"></slot>

    <div class="relative w-full">
      <div>
        <DeviceRecommend :value="value" :device="currentDevice" :max-col="maxColumn" @change="handleChangeLayout" />
      </div>
      <div class="text-dark-high mt-8">
        <span class="text-12 text-dark-high">Columns</span>
        <div class="flex flex-col gap-8">
          <GReorder :items="orderItems" @re-order="onReOrder">
            <template #default="{ item }">
              <div
                class="bg-dark-200 text-dark-high group-[.sortable-fallback]/sortable-item:bg-dark-200 text-12 my-4 flex h-36 items-center px-10 font-medium transition-all duration-200 hover:cursor-move group-[.sortable-ghost]/sortable-item:bg-neutral-700 group-[&:not(.dragging)]/sortable:hover:bg-neutral-700">
                <div class="mr-4">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M5.5 4.5C6.32843 4.5 7 3.82843 7 3C7 2.17157 6.32843 1.5 5.5 1.5C4.67157 1.5 4 2.17157 4 3C4 3.82843 4.67157 4.5 5.5 4.5Z"
                      fill="currentColor" />
                    <path
                      d="M5.5 9.5C6.32843 9.5 7 8.82843 7 8C7 7.17157 6.32843 6.5 5.5 6.5C4.67157 6.5 4 7.17157 4 8C4 8.82843 4.67157 9.5 5.5 9.5Z"
                      fill="currentColor" />
                    <path
                      d="M7 13C7 13.8284 6.32843 14.5 5.5 14.5C4.67157 14.5 4 13.8284 4 13C4 12.1716 4.67157 11.5 5.5 11.5C6.32843 11.5 7 12.1716 7 13Z"
                      fill="currentColor" />
                    <path
                      d="M10.5 4.5C11.3284 4.5 12 3.82843 12 3C12 2.17157 11.3284 1.5 10.5 1.5C9.67157 1.5 9 2.17157 9 3C9 3.82843 9.67157 4.5 10.5 4.5Z"
                      fill="currentColor" />
                    <path
                      d="M12 8C12 8.82843 11.3284 9.5 10.5 9.5C9.67157 9.5 9 8.82843 9 8C9 7.17157 9.67157 6.5 10.5 6.5C11.3284 6.5 12 7.17157 12 8Z"
                      fill="currentColor" />
                    <path
                      d="M10.5 14.5C11.3284 14.5 12 13.8284 12 13C12 12.1716 11.3284 11.5 10.5 11.5C9.67157 11.5 9 12.1716 9 13C9 13.8284 9.67157 14.5 10.5 14.5Z"
                      fill="currentColor" />
                  </svg>
                </div>
                <div class="flex gap-4">
                  <span>{{ item.title }} {{ getPosition(item.id) }}</span>
                  <!--                  <span v-if="!!layouts.length">{{ layouts[index % layouts.length] }}</span>-->
                </div>
                <div class="ml-auto flex gap-8">
                  <JustifyButton :uid="item.id" :value="justifyContentMap[item.id]" @select="onJustifySelect" />
                </div>
              </div>
            </template>
          </GReorder>
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>
