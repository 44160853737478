<script lang="ts" setup>
import { computed } from 'vue';
type PropsType = {
  id: string;
  value?: any;
  options?: {
    label?: string;
    value?: string;
    icon?: string;
    type?: string;
  }[];
  disableToggle?: boolean;
  readonly?: boolean;
  iconSmall?: boolean;
  iconViewBox?: number;
  enableItemBackground?: boolean;
};

const props = defineProps<PropsType>();
const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string, value?: string): void;
  (e: 'controlChange', controlId?: string, value?: string): void;
  (e: 'controlFocus', controlId?: string, value?: string): void;
  (e: 'controlBlur', controlId?: string, value?: string): void;
}>();

const change = (value?: string) => {
  emit('controlChange', props.id, value);
};

const iconWidth = computed(() => {
  return props.options?.length == 3 ? 72 : 50;
});
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="rounded-medium flex h-48 w-full justify-between gap-8">
      <div
        v-for="item in options"
        :key="item.value"
        :class="{
          ' !text-primary-300': value === item.value,
          ' !text-dark-200': value !== item.value,
          'bg-dark-400 border-dark-400 hover:bg-dark-300 hover:border-dark-300 rounded-medium border':
            enableItemBackground,
          ' !border-primary-300 !bg-dark-400': value == item.value && enableItemBackground,
        }"
        class="text-12 rounded-medium flex h-full cursor-pointer select-none text-center font-medium"
        @click.stop="change(item.value)">
        <g-tooltip placement="top" :disabled="!enableItemBackground">
          <span
            v-if="item.icon"
            :class="{ '[&_>_svg]:h-20': !iconSmall, '[&_>_svg]:h-12': iconSmall }"
            v-html="item.icon"></span>
          <g-base-icon
            v-else-if="item.type"
            :name="((`${item.type}-${item.value}`) as any)"
            :width="iconViewBox ? '100%' : `${iconWidth}px`"
            :height="iconViewBox ? '100%' : '48px'"
            :viewBox="iconViewBox || `0 0 ${iconWidth} 48`"
            fill="none">
          </g-base-icon>
          <span v-else class="[&_>_svg]:h-20" v-html="item.label"></span>
          <template #content>
            <div class="text-light-100 text-12">{{ item.label }}</div>
          </template>
        </g-tooltip>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>
