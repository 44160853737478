<script lang="ts" setup>
import CollectionModal from './modal/CollectionModal.vue';
import { ref, onMounted, computed } from 'vue';
import type { ProductOption } from '../type/common';

type CollectionType = {
  id: string;
  title?: string;
  cursor?: string;
  image?: string;
  options?: ProductOption[];
  status?: string;
};

type DynamicCollectionType = {
  collectionId?: string;
  collectionHandle?: string;
  collectionStatus?: string;
  collectionTitle?: string;
  collectionImage?: string;
  collectionProductCount?: number;
};

type Props = {
  id?: string;
  value?: string;
  collectionDisplay?: {
    title?: string;
    image?: string;
    productCount?: number;
  };
  isLoading?: boolean;
  collectionList?: CollectionType[];
  connectStatus?: boolean;
  syncPercent?: number;
  pageType?: string;
  pickedDynamicCollection?: DynamicCollectionType;
};
const props = withDefaults(defineProps<Props>(), {
  connectStatus: false,
});

const collectionListVisibility = ref<boolean>(false);
const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlOnSearch', controlId?: string | number, value?: any): void;
  (e: 'changeVariants', value?: any): void;
  (e: 'controlRefresh'): void;
  (e: 'connect-shopify'): void;
  (e: 'showMore'): void;
  (e: 'openAssignModal'): void;
}>();

const val = ref<string>('');

const setValueSelected = (id?: string | string[]) => {
  if (id !== undefined && typeof id === 'string') {
    val.value = id;
    emit('controlChange', props.id, val.value);
  }
};

const onSearch = (value?: string) => {
  emit('controlOnSearch', props.id, value);
};

const openModal = () => {
  if (isCollectionPage.value) {
    emit('openAssignModal');
  } else {
    collectionListVisibility.value = true;
  }
};

const closeModal = () => {
  collectionListVisibility.value = false;
};

const isCollectionPage = computed(() => {
  return props.pageType === 'GP_COLLECTION';
});

const convertCollectionDisplay = computed(() => {
  if (isCollectionPage.value) {
    const dynamicCollection = props.pickedDynamicCollection;
    if (!dynamicCollection || !dynamicCollection.collectionId) {
      return {
        title: 'No collection assigned',
      };
    } else {
      return {
        id: dynamicCollection.collectionId,
        title: dynamicCollection.collectionTitle,
        image: dynamicCollection.collectionImage,
        productCount: dynamicCollection.collectionProductCount,
      };
    }
  } else {
    return props.collectionDisplay;
  }
});

onMounted(() => {
  if (isCollectionPage.value) {
    emit('controlChange', props.id, props.value);
  }
});
</script>
<template>
  <div>
    <div class="">
      <div class="flex flex-col">
        <div class="p-8">
          <div class="w-[248px] rounded-medium mx-[-7px] flex gap-8 p-8">
            <img
              v-if="convertCollectionDisplay?.image"
              class="rounded-medium aspect-square w-32 h-32 shrink-0 object-cover"
              :src="convertCollectionDisplay?.image"
              alt="product feature img" />
            <div v-else class="rounded-medium aspect-square h-32 w-32 shrink-0 overflow-hidden">
              <svg
                v-if="isCollectionPage && (!pickedDynamicCollection || !pickedDynamicCollection.collectionId)"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21 12.5C21 12.8978 20.842 13.2794 20.5607 13.5607C20.2794 13.842 19.8978 14 19.5 14C19.1026 13.997 18.7218 13.8402 18.4375 13.5625C18.2978 13.4233 18.1871 13.2576 18.112 13.0752C18.0369 12.8928 17.9988 12.6973 18 12.5C18 12.1022 18.158 11.7206 18.4393 11.4393C18.7206 11.158 19.1022 11 19.5 11C19.8978 11 20.2794 11.158 20.5607 11.4393C20.842 11.7206 21 12.1022 21 12.5ZM29 7V25C29 25.5304 28.7893 26.0391 28.4142 26.4142C28.0391 26.7893 27.5304 27 27 27H5C4.46957 27 3.96086 26.7893 3.58579 26.4142C3.21071 26.0391 3 25.5304 3 25V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H27C27.5304 5 28.0391 5.21071 28.4142 5.58579C28.7893 5.96086 29 6.46957 29 7ZM27 20.5875V7H5V18.5875L9.5875 14C9.96403 13.629 10.4714 13.421 11 13.421C11.5286 13.421 12.036 13.629 12.4125 14L18 19.5875L20.5875 17C20.964 16.629 21.4714 16.421 22 16.421C22.5286 16.421 23.036 16.629 23.4125 17L27 20.5875Z"
                  fill="#757575" />
              </svg>
              <svg v-else width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_280_39360)">
                  <rect width="32" height="32" fill="#EEEEEE" />
                  <path
                    d="M18.8125 14.0312C18.8125 14.255 18.7236 14.4696 18.5654 14.6279C18.4071 14.7861 18.1925 14.875 17.9688 14.875C17.7452 14.8733 17.531 14.7851 17.3711 14.6289C17.2925 14.5506 17.2303 14.4574 17.188 14.3548C17.1458 14.2522 17.1243 14.1422 17.125 14.0312C17.125 13.8075 17.2139 13.5929 17.3721 13.4346C17.5304 13.2764 17.745 13.1875 17.9688 13.1875C18.1925 13.1875 18.4071 13.2764 18.5654 13.4346C18.7236 13.5929 18.8125 13.8075 18.8125 14.0312ZM23.3125 10.9375V21.0625C23.3125 21.3609 23.194 21.647 22.983 21.858C22.772 22.069 22.4859 22.1875 22.1875 22.1875H9.8125C9.51413 22.1875 9.22798 22.069 9.017 21.858C8.80603 21.647 8.6875 21.3609 8.6875 21.0625V10.9375C8.6875 10.6391 8.80603 10.353 9.017 10.142C9.22798 9.93103 9.51413 9.8125 9.8125 9.8125H22.1875C22.4859 9.8125 22.772 9.93103 22.983 10.142C23.194 10.353 23.3125 10.6391 23.3125 10.9375ZM22.1875 18.5805V10.9375H9.8125V17.4555L12.393 14.875C12.6048 14.6663 12.8902 14.5493 13.1875 14.5493C13.4848 14.5493 13.7702 14.6663 13.982 14.875L17.125 18.018L18.5805 16.5625C18.7923 16.3538 19.0777 16.2368 19.375 16.2368C19.6723 16.2368 19.9577 16.3538 20.1695 16.5625L22.1875 18.5805Z"
                    fill="#494949" />
                </g>
                <defs>
                  <clipPath id="clip0_280_39360">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div class="flex flex-col justify-center">
              <p class="text-dark-high font-regular text-12 line-clamp-1">
                {{ convertCollectionDisplay?.title }}
              </p>
              <p
                v-if="Number.isInteger(convertCollectionDisplay?.productCount)"
                class="text-dark-low font-regular text-12 line-clamp-2 italic">
                {{ convertCollectionDisplay?.productCount }}
                {{
                  convertCollectionDisplay?.productCount && convertCollectionDisplay?.productCount > 1
                    ? 'products'
                    : ' product'
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="select-collection-btn flex">
          <g-button
            type="tertiary"
            button-classes="flex h-[36px] w-full items-center justify-center gap-8"
            @click="openModal">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 1.35181C10.8451 1.35181 11.125 1.63163 11.125 1.97681V3.15303C14.4232 3.45024 17.0497 6.07679 17.3469 9.375H18.5234C18.8686 9.375 19.1484 9.65482 19.1484 10C19.1484 10.3452 18.8686 10.625 18.5234 10.625H17.3469C17.0497 13.9232 14.4232 16.5498 11.125 16.847V18.0234C11.125 18.3686 10.8451 18.6484 10.5 18.6484C10.1548 18.6484 9.87495 18.3686 9.87495 18.0234V16.847C6.57674 16.5498 3.9502 13.9232 3.65298 10.625H2.47668C2.13151 10.625 1.85168 10.3452 1.85168 10C1.85168 9.65482 2.13151 9.375 2.47668 9.375H3.65298C3.9502 6.07679 6.57674 3.45024 9.87495 3.15303V1.97681C9.87495 1.63163 10.1548 1.35181 10.5 1.35181ZM4.90928 10.625H7.47668C7.82186 10.625 8.10168 10.3452 8.10168 10C8.10168 9.65482 7.82186 9.375 7.47668 9.375H4.90928C5.1975 6.76774 7.26769 4.69755 9.87495 4.40933V6.97681C9.87495 7.32198 10.1548 7.60181 10.5 7.60181C10.8451 7.60181 11.125 7.32198 11.125 6.97681V4.40933C13.7322 4.69755 15.8024 6.76774 16.0906 9.375H13.5234C13.1782 9.375 12.8984 9.65482 12.8984 10C12.8984 10.3452 13.1782 10.625 13.5234 10.625H16.0906C15.8024 13.2323 13.7322 15.3025 11.125 15.5907V13.0234C11.125 12.6782 10.8451 12.3984 10.5 12.3984C10.1548 12.3984 9.87495 12.6782 9.87495 13.0234V15.5907C7.26769 15.3025 5.1975 13.2323 4.90928 10.625Z" fill="white"/>
            </svg>
            <p class="text-12 text-white">
              {{
                isCollectionPage
                  ? pickedDynamicCollection
                    ? 'Change preview'
                    : 'Assign collection'
                  : 'Pick collection'
              }}
            </p>
          </g-button>
        </div>
      </div>
      <CollectionModal
        :open="collectionListVisibility"
        :list="props.collectionList"
        :value="props.value"
        :sync-percent="syncPercent"
        :is-loading="isLoading"
        type="Collection"
        :page-type="pageType"
        @show-more="emit('showMore')"
        @refresh="emit('controlRefresh')"
        @close="closeModal"
        @set-value-selected="setValueSelected"
        @on-search="onSearch" />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
