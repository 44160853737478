<script lang="ts" setup>
import { ref } from 'vue';
import { icons } from './icons';

type Prop = {
  id?: string;
  item?: {
    class: string;
    pos: string;
    property: string;
  };
  value?: string | number;
  isOpenSelectSpacing?: boolean;
  fillOutside?: string;
};

const props = defineProps<Prop>();
const emits = defineEmits(['outside-mouseleave', 'outside-mouseover', 'change-diff-value']);

const iniX = ref(0);
const iniY = ref(0);

function onMouseDown(event: MouseEvent) {
  iniX.value = event.pageX;
  iniY.value = event.pageY;

  if ((event.target as any).tagName !== 'INPUT') {
    window.addEventListener('mousemove', onMouseMove);
  }

  window.addEventListener('mouseup', onMouseUp);
}

function onMouseMove(event: MouseEvent) {
  const diff = iniY.value - event.pageY;
  emits('change-diff-value', props.item?.property, diff);
}

function onMouseUp() {
  window.removeEventListener('mousemove', onMouseMove);
  window.removeEventListener('mouseup', onMouseUp);
}

function withIcon(iconName: string) {
  return icons[iconName as keyof typeof icons];
}

function handleMouseOver() {
  emits('outside-mouseover', props.item?.property);
}

function handleMouseLeave() {
  if (props.isOpenSelectSpacing) {
    return;
  }

  emits('outside-mouseleave', props.item?.property);
}
</script>

<template>
  <component
    :is="withIcon(item.property)"
    :color="fillOutside"
    @mousedown.stop="onMouseDown"
    @mouseleave.stop="handleMouseLeave"
    @mouseover.stop="handleMouseOver" />
</template>

<style scoped>
path:hover {
  fill: #424242 !important;
}
</style>
