<script lang="ts" setup>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ref } from 'vue';
import SwatchesDropdown from './components/SwatchesDropdown.vue';
import type { SwatchesOptionType, SwatchesOptionValue } from './types';

const emit = defineEmits<{
  (e: 'change', value: SwatchesOptionValue[]): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
}>();

const props = defineProps<{
  index: string;
}>();

const sampleRadioData = [
  { label: 'Extra Small' },
  { label: 'S' },
  { label: 'M' },
  { label: 'L' },
  { label: 'Extra Large' },
];

const select = ref(sampleRadioData?.[0]);

const handleChangeType = (type?: SwatchesOptionType) => {
  if (type) {
    emit('typeChange', +props.index, type);
  }
};
</script>

<template>
  <div class="bg-light-400 flex gap-44 p-20">
    <div class="flex flex-col">
      <div class="text-light-high text-14 mb-10">Option Type</div>
      <SwatchesDropdown :index="index" type="dropdown" @type-change="handleChangeType" />
    </div>

    <div class="flex-grow">
      <div class="text-light-high text-14 mb-10">Preview Option Values</div>
      <Listbox v-model="select" class="text-14 rounded-medium mb-8 min-h-[30px] w-[150px] bg-white px-8 py-4">
        <div class="relative">
          <ListboxButton class="relative flex h-32 w-full cursor-pointer items-center text-left">
            <span class="inline-block truncate">{{ select?.label }}</span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-20 w-20"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
              </svg>
            </span>
          </ListboxButton>

          <ListboxOptions class="absolute left-0 z-50 w-full bg-white p-8">
            <ListboxOption
              v-for="item in sampleRadioData"
              :key="item.label"
              :value="item"
              class="hover:text-primary-325 cursor-pointer py-4">
              {{ item.label }}
            </ListboxOption>
          </ListboxOptions>
        </div>
      </Listbox>
      <div class="text-12 text-light-disabled mt-16">Option values will be displayed in a dropdown menu</div>
    </div>
  </div>
</template>
