import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type GlobalSettingCreateOrUpdateMutationVariables = Types.Exact<{
  input: Types.UpsertGlobalSettingInput;
}>;

export type GlobalSettingCreateOrUpdateMutationResponse = {
  globalSettingCreateOrUpdate?: Types.Maybe<
    Pick<Types.GlobalSetting, 'createdAt' | 'id' | 'key' | 'updatedAt' | 'value'>
  >;
};

export const GlobalSettingCreateOrUpdateDocument = `
    mutation globalSettingCreateOrUpdate($input: UpsertGlobalSettingInput!) {
  globalSettingCreateOrUpdate(input: $input) {
    createdAt
    id
    key
    updatedAt
    value
  }
}
    `;
export const useGlobalSettingCreateOrUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    GlobalSettingCreateOrUpdateMutationResponse,
    TError,
    GlobalSettingCreateOrUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<
    GlobalSettingCreateOrUpdateMutationResponse,
    TError,
    GlobalSettingCreateOrUpdateMutationVariables,
    TContext
  >(
    ['globalSettingCreateOrUpdate'],
    (
      variables:
        | GlobalSettingCreateOrUpdateMutationVariables
        | ComputedRef<GlobalSettingCreateOrUpdateMutationVariables>
        | Ref<GlobalSettingCreateOrUpdateMutationVariables>,
    ) =>
      appFetcher<GlobalSettingCreateOrUpdateMutationResponse, GlobalSettingCreateOrUpdateMutationVariables>(
        GlobalSettingCreateOrUpdateDocument,
        variables,
      )(),
    options,
  );
useGlobalSettingCreateOrUpdateMutation.getKey = () => ['globalSettingCreateOrUpdate'];
