export const hasImageShopify = function (src: string) {
  // Return true, false
  if (!src) {
    return false;
  }
  const arrayPath = ['cdn.shopify.com/s/files/', 'apps.shopifycdn.com/', '/cdn/shop/'];
  return arrayPath.some((path) => src.includes(path));
};

/**
 * Hàm replace image theo size mong muốn
 * @param {String} src link ảnh
 * @param {String} expectImageSize size ảnh: 200x200, 200x, x200
 * @return {String} link ảnh tạo mới
 */
export const replaceImageToSize = (src: string, expectImageSize: string) => {
  if (!src || expectImageSize == undefined || expectImageSize == null) {
    return src;
  }
  if (hasImageShopify(src)) {
    const ignore = ['jfif'];
    let params = '';
    const splitParams = src.split('?');
    if (splitParams && splitParams.length && splitParams.length >= 2) {
      params = splitParams[1];
    }
    const arrImage = splitParams[0].split('/').pop() ?? '';
    const slugName = arrImage.split('.');
    const strExtention = slugName.pop() ?? '';
    if (ignore.indexOf(strExtention) !== -1) {
      return src;
    }
    let nameImages = slugName.join('.');
    const arrayNames = nameImages.split('_');
    if (arrayNames && arrayNames.length >= 2) {
      const sizeCurrent = arrayNames.pop();
      const reg = new RegExp(/(\d+)x(\d+)|(\d+)x|x(\d+)/, 'gm');
      if (sizeCurrent && reg.test(sizeCurrent)) {
        const trimReg = sizeCurrent.replace(reg, '');
        if (trimReg == '') {
          const nameImagesExecute = nameImages.split('_');
          nameImagesExecute.pop();
          nameImages = nameImagesExecute.join('_');
        }
      }
    }

    const srcImageSplit = src.split('?')[0].split('/');
    let smallSrc = '';
    for (let j = 0; j < srcImageSplit.length - 1; j++) {
      smallSrc += srcImageSplit[j] + '/';
    }

    if (expectImageSize) {
      src = smallSrc + nameImages + '_' + expectImageSize + '.' + strExtention;
    } else {
      src = smallSrc + nameImages + '.' + strExtention;
    }

    if (params) {
      src = src + '?' + params;
    }
  }
  return src;
};
