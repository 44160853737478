<script lang="ts" setup>
import { computed, toRef } from 'vue';
import TemplateColumn from './TemplateColumn.vue';
import { useGridLayout, useGridLayoutToArray } from '../hooks';

type Props = {
  columns: string;
  deviceName?: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'control-change', value: string): void;
}>();

const layouts = useGridLayoutToArray(toRef(props, 'columns'));

const customLayout = useGridLayout(toRef(props, 'columns'));

const gridStyleCustomLayout = computed(() => {
  const templateColumns = customLayout.value.map((v) => {
    if (v === 'auto') {
      return 'auto';
    }
    if (v.endsWith('px')) return '44px';
    return v;
  });
  return { gridTemplateColumns: templateColumns.join(' ') };
});

const onChange = (value: string, index: number) => {
  const newVal = [...layouts.value];
  newVal[index] = value === 'auto' ? value : `minmax(0, ${value})`;
  emit('control-change', newVal.join(' '));
};
</script>

<template>
  <div class="mb-16">
    <div class="text-12 flex items-center text-[#a6a6a6]">
      <slot name="icon"></slot>
      {{ deviceName }}
    </div>

    <div class="bg-dark-200 relative grid h-32 gap-[1px]" :style="gridStyleCustomLayout">
      <div
        v-for="(col, i) in customLayout"
        :key="i"
        :class="{ 'bg-[#f19620]': !!col.endsWith('px'), 'bg-dark-400': !col.endsWith('px') }"
        class="text-12 flex select-none items-center justify-center px-4 text-[#e2e2e2]">
        <g-popover
          :overlay="true"
          overlay-container="#setting-panel"
          placement="bottom-end"
          cls="!bg-dark-300"
          arrow-class="!border-r-dark-300"
          button-class="w-full h-full items-center justify-center flex-1"
          wrap-content-class="w-full h-full flex justify-center items-center cursor-pointer">
          <span>{{ col }}</span>
          <template #content>
            <TemplateColumn :index="i" :value="col" @change="onChange" />
          </template>
        </g-popover>
      </div>
    </div>
  </div>
</template>
