import type { BgPositionOption } from './types';

export const BG_POSITION_OPTIONS: BgPositionOption[] = [
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10"> <path d="M2,2v6H0V0h8v2H2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 0,
      y: 0,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10"> <path d="M10,2H0V0h10V2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 50,
      y: 0,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10" style="transform: rotate(90deg);"> <path d="M2,2v6H0V0h8v2H2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 100,
      y: 0,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10" style="transform: rotate(-90deg);"> <path d="M10,2H0V0h10V2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 0,
      y: 50,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10"> <circle class="st1" cx="5" cy="5" r="3" style="fill: #DADADB;"></circle> </svg>',
    value: {
      x: 50,
      y: 50,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10" style="transform: rotate(90deg);"> <path d="M10,2H0V0h10V2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 100,
      y: 50,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10" style="transform: rotate(-90deg);"> <path d="M2,2v6H0V0h8v2H2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 0,
      y: 100,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10" style="transform: rotate(180deg);"> <path d="M10,2H0V0h10V2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 50,
      y: 100,
    },
  },
  {
    icon: '<svg class="help-me-name-it-bruh" height="10px" viewBox="0 0 10 10" style="transform: rotate(180deg);"> <path d="M2,2v6H0V0h8v2H2z" style="fill: #DADADB;"></path> </svg>',
    value: {
      x: 100,
      y: 100,
    },
  },
];
