<script lang="ts" setup>
import { computed } from 'vue';
import parseUnit from '../../../helpers/parse-unit';
import NumberUnit from '../../input/NumberUnit.vue';

type Props = {
  value: string;
  index: number;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'change', value: string, index: number): void;
}>();
const cssValue = computed(() => parseUnit(props.value));
const pxList = ['auto', '50px', '80px', '100px', '120px', '200px', '250px', '400px'];
const frList = ['auto', '1fr', '2fr', '3fr', '4fr', '6fr', '8fr', '12fr', '24fr'];
const recommends = computed(() => {
  return cssValue.value[1] === 'px' ? pxList : frList;
});

const onChange = (value: string) => {
  emit('change', value, props.index);
};
</script>

<template>
  <div class="flex w-[250px] flex-col gap-8">
    <NumberUnit v-if="value !== 'auto'" :min="1" :value="value" :units="['px', 'fr']" @change="onChange" />
    <div class="flex flex-wrap gap-4">
      <button
        v-for="item in recommends"
        :key="item"
        class="rounded-small bg-dark-400 hover:bg-dark-200 px-8 py-4"
        :class="{
          'bg-dark-200': value === item,
        }"
        @click="onChange(item)">
        {{ item }}
      </button>
    </div>
  </div>
</template>
