<script lang="ts" setup>
import Segment from './../Segment.vue';
import Input from './Input.vue';

type CustomContent = {
  prefix?: string;
  suffix?: string;
  unit?: string;
};

type Props = {
  id?: string;
  value?: CustomContent;
  placeholder?: string;
  controlChange?: (id?: string, value?: any) => void;
};

const emit = defineEmits<{
  controlOnChange: [controlId?: string | number, value?: CustomContent];
}>();

const props = withDefaults(defineProps<Props>(), {});

const controlOnChange = (id: string, value?: string) => {
  if (id) emit('controlOnChange', props.id, { ...props.value, [id]: value });
};

const controlChange = (id: string, value?: string) => {
  if (id) props.controlChange?.(props.id, { ...props.value, [id]: value });
};

const options = [
  {
    label: '%',
    value: 'percentage',
  },
  {
    label: 'Currency',
    value: 'price',
  },
];
</script>

<template>
  <div class="flex flex-col">
    <div class="mb-16 flex">
      <p class="gemx-control-label text-12 text-dark-low flex items-center py-8">Type</p>
      <Segment
        id="unit"
        :options="options"
        :value="value?.unit"
        class="max-w-input-horizontal ml-auto w-full"
        @control-change="controlChange" />
    </div>
    <div class="flex">
      <span class="text-12 text-dark-low py-8">Content</span>
      <div class="max-w-input-horizontal ml-auto flex w-full items-center gap-[6px]">
        <Input
          control-id="prefix"
          :value="value?.prefix"
          placeholder="SALE"
          @control-change="controlChange"
          @control-on-change="controlOnChange" />
        <span class="text-dark-disabled text-12 font-regular">
          {{ value?.unit === 'percentage' ? '%' : '$' }}
        </span>
        <Input
          control-id="suffix"
          :value="value?.suffix"
          placeholder="OFF"
          @control-change="controlChange"
          @control-on-change="controlOnChange" />
      </div>
    </div>
  </div>
</template>
