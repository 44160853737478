<template>
  <button
    type="button"
    role="button"
    aria-pressed="false"
    class="gt_text_format_underline item"
    @click="setFormat('.gt_text_format_underline')">
    <svg focusable="false" width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="fr-svg">
      <path
        d="M19,20v2H5V20Zm-3-6.785a4,4,0,0,1-5.74,3.4A3.75,3.75,0,0,1,8,13.085V5.005H6v8.21a6,6,0,0,0,8,5.44,5.851,5.851,0,0,0,4-5.65v-8H16ZM16,5v0h2V5ZM8,5H6v0H8Z" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'FormatUnderline',
  emits: ['statusFormatText'],
  methods: {
    setFormat(classButton) {
      const $parrantButton = event.target.closest(classButton);
      this.$emit('statusFormatText', {
        type: 'underline',
        classButton: $parrantButton,
      });
    },
  },
};
</script>
