<script lang="ts" setup>
import { GButton, GBaseIcon } from '@gem/uikit';
import { nanoid } from 'nanoid';
import { ref, onUnmounted } from 'vue';
const props = defineProps<{
  id: string;
  value?: number | string;
  defaultControl: any;
  controlChange?: (id: string, value?: any) => void;
}>();

const timer = ref<number>();
const loading = ref(false);

const change = () => {
  clearTimeout(timer.value);
  loading.value = true;
  props.controlChange?.(props.id, nanoid());
  setTimeout(() => {
    loading.value = false;
  }, 1000);
};
onUnmounted(() => {
  clearTimeout(timer.value);
});
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <GButton
      size="medium"
      type="tertiary"
      :disable="loading"
      button-classes="flex h-[32px] w-full items-center justify-center"
      @click.prevent="change">
      <div class="flex h-full items-center justify-center gap-4 font-medium">
        <GBaseIcon
          name="reload"
          :class="{
            'animate-spin': loading,
          }"
          class="h-16"></GBaseIcon>
        <span class="text-12">
          {{ defaultControl?.buttonText }}
        </span>
      </div>
    </GButton>
    <slot name="info"></slot>
  </div>
</template>
