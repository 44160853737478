import { nanoid } from 'nanoid';
export const ID = (size = 9) => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return `g${nanoid(size)}`;
};

export const RenderSectionCID = (expelStoreCIDs: string[], size?: number) => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  const sizeConfig = size || 9;
  let newID = `g${nanoid(sizeConfig)}`;
  if (expelStoreCIDs.includes(newID)) newID = RenderSectionCID(expelStoreCIDs, sizeConfig);
  return newID;
};
