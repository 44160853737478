// Example: 8 => [1, 2, 4, 8]
export const getFactors = (value: number) =>
  Array.from({ length: value + 1 }, (_, i) => i).filter((i) => value % i === 0);

// Example: 15px => [15, 'px']
export const parseCSSUnit = (value: string) => {
  const num = parseFloat(value);
  const unit = (value.match(/[\d.\-+]*\s*(.*)/) as RegExpMatchArray)[1];
  return [num, unit];
};

export const byteSize = (str: string) => new Blob([str]).size;
