function add(accumulator: number, a: number) {
  return accumulator + a;
}

export function sumArr(arr: number[], index: number) {
  return [...arr].slice(0, index + 1).reduce(add, 0);
}

// [1,2,5,5];
export const getPositionValue = (values: number[], index: number) => {
  const min = index === 0 ? 1 : sumArr(values, index - 1) + 1;
  const max = sumArr(values, index + 1);
  const diff = max - min;
  return {
    canEdit: diff > 1,
    min,
    max: max - 1,
  };
};

export const arrange = (values: number[], index: number, pos: number) => {
  const { canEdit, min, max } = getPositionValue(values, index);
  if (!canEdit || min > pos || max < pos) return values;

  const current = sumArr(values, index);
  const left = values[index];
  const right = values[index + 1];
  const diff = pos - current;
  values[index] = left + diff;
  values[index + 1] = right - diff;
  return values;
};
