import type { GlobalSwatchesData } from '@/types/global-data';
import type { PageSection } from '@/types/graphql';
import { cloneDeepObject, isDefined } from '@/utils/common';
import {
  convertComponentToJSON,
  createBuilderComponentBySection,
  modifyComponentBeforeBuilderState,
} from '../../common/utils/section/component';
import type { Component, GroupTypeSetting } from '../../common/utils/types';
import useBuilderConfigStore from '../../component-preset/stores/builder-config';
import {
  emitAddEntity,
  emitInitBuilder,
  emitMoveEntity,
  emitRemoveEntity,
  emitSetGlobalStyle,
  emitUpdateEntityProp,
  emitUpdateGlobalSwatchesData,
} from './emitToIframe';

export function builderInit(sections: PageSection[], callback?: () => void) {
  if (sections?.length) {
    const builderConfigStore = useBuilderConfigStore();
    const builderSettings = cloneDeepObject(builderConfigStore.getBuilderSettings || {});
    const components = sections
      .map((section) => createBuilderComponentBySection(section, builderSettings))
      .filter(isDefined);
    // Emit new state
    emitInitBuilder(components, callback);
  } else {
    emitInitBuilder([], callback);
  }
}

export function builderAddSection(section: PageSection, position: number, callback?: () => void) {
  const builderConfigStore = useBuilderConfigStore();
  const builderSettings = cloneDeepObject(builderConfigStore.getBuilderSettings || {});
  const component = createBuilderComponentBySection(section, builderSettings);
  if (component) {
    emitAddEntity(
      {
        entity: component,
        position: position,
      },
      callback,
    );
  }
}

export function builderAddComponent(component: Component, id: string, position: number, callback?: () => void) {
  if (component) {
    const builderConfigStore = useBuilderConfigStore();
    const builderSettings = cloneDeepObject(builderConfigStore.getBuilderSettings || {});
    const cloneComponent = cloneDeepObject(component);
    modifyComponentBeforeBuilderState(cloneComponent, builderSettings);
    emitAddEntity(
      {
        entity: cloneComponent,
        id,
        position,
      },
      callback,
    );
  }
}

export function builderDeleteSection(section: PageSection, callback?: () => void) {
  const component = convertComponentToJSON(section?.component || '');
  if (component?.uid) {
    emitRemoveEntity(component.uid, callback);
  }
}

export function builderMoveComponent(data: { uid: string; to: string; position: number }, callback?: () => void) {
  emitMoveEntity(data, callback);
}

export function builderDeleteComponent(uid: string, callback?: () => void) {
  emitRemoveEntity(uid, callback);
}

export function builderUpdateComponent(
  data: { uid: string; propName: string; propValue: any; group: GroupTypeSetting },
  callback?: () => void,
) {
  emitUpdateEntityProp(data, callback);
}

export function builderSetGlobalStyle(
  { data, mobileOnly }: { data: any; mobileOnly?: boolean },
  callback?: () => void,
) {
  if (data) {
    emitSetGlobalStyle(
      {
        globalStyle: data,
        mobileOnly,
      },
      callback,
    );
  }
}

export function builderUpdateSwatchesData(swatchesData: GlobalSwatchesData[], callback?: () => void) {
  if (swatchesData) {
    emitUpdateGlobalSwatchesData(
      {
        swatchesData: swatchesData,
      },
      callback,
    );
  }
}
