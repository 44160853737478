<script lang="ts" setup>
import { reactive, ref } from 'vue';
import Modal from './modal/Modal.vue';

type Props = {
  id: string;
  value?: string;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

type TransformFunction = {
  translate3d: {
    x: number | string;
    y: number | string;
    z: number | string;
  };
  rotate: {
    x: number | string;
    y: number | string;
    z: number | string;
  };
  scale: {
    x: number | string;
    y: number | string;
    z: number | string;
  };
  skew: {
    x: number | string;
    y: number | string;
    z?: number | string;
  };
};

type ItemType = keyof TransformFunction;

const transformFunction = reactive<TransformFunction>({
  translate3d: { x: 0, y: 0, z: 0 },
  rotate: { x: 0, y: 0, z: 0 },
  scale: { x: 0, y: 0, z: 0 },
  skew: { x: 0, y: 0 },
});
const transformStyle = ref('');
const transformRange = reactive({
  translate3d: { min: -1000, max: 1000 },
  rotate: { min: -180, max: 180 },
  scale: { min: -5, max: 5 },
  skew: { min: -180, max: 180 },
});
const transformModal = ref(false);

const transformFunctionKey = ref<keyof TransformFunction>('translate3d');

const detectValue = (value: string) => {
  const a = value.split('(');
  const func = a[0]
    .toLocaleLowerCase()
    .trim()
    .substring(0, a[0].length - 1);
  //regex number

  const positionValue = value.match(/\d+/g);
  switch (func) {
    case 'translate3d':
      transformFunctionKey.value = func;
      break;
    case 'rotate':
      transformFunctionKey.value = func;
      break;
    case 'scale':
      transformFunctionKey.value = func;
      break;
    case 'skew':
      transformFunctionKey.value = func;
      break;
  }
  if (positionValue) {
    transformFunction[transformFunctionKey.value].x = positionValue[0];
    transformFunction[transformFunctionKey.value].y = positionValue[1];
    transformFunction[transformFunctionKey.value].z = positionValue[2];
  }
};
detectValue('translate3d(268px, 0px, 0px)');

const changeTab = (value: ItemType) => {
  transformFunctionKey.value = value;
  emit('controlChange', props.id, transformStyle.value);
};
const changeTransform = (pos: 'x' | 'y' | 'z', value?: string | number) => {
  if (value !== undefined) {
    transformFunction[transformFunctionKey.value][pos] = value;
    switch (transformFunctionKey.value) {
      case 'translate3d': {
        transformStyle.value = `translate3d(${transformFunction.translate3d.x}px, ${transformFunction.translate3d.y}px, ${transformFunction.translate3d.z}px)`;
        break;
      }
      case 'rotate': {
        transformStyle.value = `rotateX(${transformFunction.rotate.x}deg) rotateY(${transformFunction.rotate.y}deg) rotateZ(${transformFunction.rotate.z}deg)`;
        break;
      }
      case 'scale': {
        transformStyle.value = `scale(${transformFunction.scale.x}, ${transformFunction.scale.y}, ${transformFunction.scale.z})`;
        break;
      }
      case 'skew': {
        transformStyle.value = `skew(${transformFunction.skew.x}, ${transformFunction.skew.y})`;
        break;
      }
    }
  }
};
</script>
<template>
  <div class="sf--control">
    <div class="sf--control--transform text-left" @click="transformModal = true">
      <p class="mx-2 px-1">
        {{ transformFunctionKey.toLocaleUpperCase() }} :
        {{
          `${transformFunction[transformFunctionKey].x}, ${transformFunction[transformFunctionKey].y}, ${transformFunction[transformFunctionKey].z}`
        }}
      </p>
    </div>
    <Modal v-if="transformModal" :hide-actions="true" @close.stop="" @cancel.stop="">
      <div class="p-2">
        <div class="mb-3 flex">
          <div
            v-for="(item, index) in Object.keys(transformFunction)"
            :key="index"
            class="px-2 hover:bg-gray-500 hover:text-white"
            :class="{ 'bg-blue-500': transformFunctionKey === item }"
            @click="changeTab(item as ItemType)">
            {{ item.toUpperCase() }}
          </div>
        </div>
        <div class="flex flex-col">
          <div class="my-2">
            <div class="flex h-24 w-full items-center justify-center overflow-hidden border">
              <div
                class="h-5 w-5 bg-slate-700"
                :style="{
                  transform: transformStyle,
                }"></div>
            </div>
          </div>
          <div class="flex">
            <span class="mr-1">X</span>
            <input
              v-model="transformFunction[transformFunctionKey].x"
              class="w-4/5"
              type="range"
              step="1"
              :min="transformRange[transformFunctionKey].min"
              :max="transformRange[transformFunctionKey].max"
              @change="changeTransform('x', transformFunction[transformFunctionKey].x)" />
            <span class="ml-auto">
              {{ transformFunction[transformFunctionKey].x }}
            </span>
          </div>

          <div class="flex">
            <span class="mr-1">Y</span>
            <input
              v-model="transformFunction[transformFunctionKey].y"
              class="w-4/5"
              type="range"
              step="1"
              :min="transformRange[transformFunctionKey].min"
              :max="transformRange[transformFunctionKey].max"
              @change="changeTransform('y', transformFunction[transformFunctionKey].y)" />
            <span class="ml-auto">
              {{ transformFunction[transformFunctionKey].y }}
            </span>
          </div>
          <div v-if="transformFunctionKey !== 'skew'" class="flex">
            <span class="mr-1">Z</span>
            <input
              v-model="transformFunction[transformFunctionKey].z"
              class="w-4/5"
              type="range"
              step="1"
              :min="transformRange[transformFunctionKey].min"
              :max="transformRange[transformFunctionKey].max"
              @change="changeTransform('z', transformFunction[transformFunctionKey].z)" />
            <span class="ml-auto">
              {{ transformFunction[transformFunctionKey].z }}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<style lang="scss">
@import './../utils/common.scss';
</style>
