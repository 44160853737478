<script lang="ts" setup>
import { GBaseIcon, GModal, GCollapseTransition } from '@gem/uikit';

import { computed, ref } from 'vue';
import type { File } from '../type/graphql';
import SwatchesInput from './swatches/components/SwatchesInput.vue';
import SwatchesColor from './swatches/SwatchesColor.vue';
import SwatchesImage from './swatches/SwatchesImage.vue';
import SwatchesManageDropdown from './swatches/SwatchesManageDropdown.vue';
import SwatchesRadio from './swatches/SwatchesRadio.vue';
import SwatchesRectList from './swatches/SwatchesRectList.vue';
import type { GlobalSwatchesData, SwatchesOptionType, SwatchesOptionValue } from './swatches/types';
import SwatchesShopifyImage from './swatches/SwatchesShopifyImage.vue';

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'uploadImage', formData: FormData, index?: string, toVariant?: string): void;
  (e: 'addItem', data: GlobalSwatchesData[]): void;
  (e: 'save'): void;
  (e: 'changeData', value: GlobalSwatchesData[]): void;
}>();

const props = withDefaults(
  defineProps<{
    data?: GlobalSwatchesData[];
    images?: File[];
    maximumSize?: number;
    allowedFiles?: string[];
    shopId: string;
  }>(),
  {
    maximumSize: 2 * 1024 * 1024, // Byte
    allowedFiles: () => ['image/avif', 'image/jpeg', 'image/tiff', 'image/bmp', 'image/gif', 'image/png', 'image/webp'],
  },
);
const listActive = ref<number[]>([]);
const data = computed(() => props.data ?? []);

const handleInitComponent = (optionType: SwatchesOptionType) => {
  switch (optionType) {
    case 'color':
      return SwatchesColor;
    case 'image':
      return SwatchesImage;
    case 'rectangle_list':
      return SwatchesRectList;
    case 'dropdown':
      return SwatchesManageDropdown;
    case 'radio_buttons':
      return SwatchesRadio;
    case 'image_shopify':
      return SwatchesShopifyImage;
  }
};

const handleDropdownItem = (index: number) => {
  if (listActive.value.includes(index)) {
    listActive.value = listActive.value.filter((v) => v !== index);
  } else {
    listActive.value = [...listActive.value, index];
  }
};

const handleUploadImage = (formData: FormData, index: string, toVariant: string) => {
  emit('uploadImage', formData, index, toVariant);
};

const handleAppendItem = () => {
  const newItem: GlobalSwatchesData = {
    optionTitle: 'New Item',
    optionType: 'color',
    optionValues: [
      {
        label: 'New Item',
        colors: [],
        imageUrl: '',
      },
    ],
  };
  const dataClone: GlobalSwatchesData[] = [...data.value, newItem];

  emit('addItem', dataClone);
};

const closeModal = () => {
  emit('close');
};

const saveModal = () => {
  emit('save');
};

const handleChangeData = (dataChange: SwatchesOptionValue[], index: string) => {
  const newData = data.value.map((v, i) => {
    if (i === Number(index)) {
      return {
        ...v,
        optionValues: dataChange,
      };
    }
    return v;
  });

  emit('changeData', newData);
};

const handleChangeType = (index: number, type?: SwatchesOptionType) => {
  if (type) {
    const newData = JSON.parse(JSON.stringify(data.value));
    newData[index].optionType = type;
    emit('changeData', newData);
  }
};

const handleChangeTitle = (index: number, title?: string) => {
  if (title) {
    const newData = JSON.parse(JSON.stringify(data.value));
    newData[index].optionTitle = title;
    emit('changeData', newData);
  }
};

const handleDeleteItem = (index: number) => {
  if (typeof index === 'number') {
    const newData = JSON.parse(JSON.stringify(data.value));
    newData?.splice(index, 1);
    emit('changeData', newData);
  }
};
</script>

<template>
  <g-modal :is-open="true" :show-btn-close="true" @cancel="closeModal" @close="closeModal" @ok="saveModal">
    <template #title>Manage Swatches</template>
    <template #default>
      <perfect-scrollbar class="!h-[600px]">
        <div class="w-[700px] p-20">
          <div v-for="(item, index) in data" :key="index" class="mb-20 border border-solid">
            <div
              class="flex cursor-pointer items-center justify-between bg-slate-300 py-12 px-20"
              @click.stop.self="handleDropdownItem(index)">
              <div class="flex items-center gap-8">
                <span
                  class="inline-flex transition-transform duration-200"
                  :class="{ 'rotate-180': !listActive.includes(index) }"
                  @click="handleDropdownItem(index)">
                  <g-base-icon name="arrow-up" width="25" height="25"></g-base-icon>
                </span>
                <span class="text-light-high font-medium">Option Title</span>
                <SwatchesInput
                  :value="item.optionTitle"
                  @change-option-title="(value: string) => handleChangeTitle(index, value)" />
              </div>
              <g-button type="ghost" size="medium" button-classes="!px-8" @click="handleDeleteItem(index)">
                <span class="text-light-high gap-8">
                  <g-base-icon name="trash" width="20" height="20" view-box="0 0 16 16"></g-base-icon>
                </span>
              </g-button>
            </div>

            <g-collapse-transition :duration="200">
              <div v-if="!listActive.includes(index)">
                <component
                  :is="handleInitComponent(item.optionType)"
                  :index="index.toString()"
                  :data="item.optionValues"
                  :images="images"
                  :shop-id="shopId"
                  :maximum-size="maximumSize"
                  :allowed-files="allowedFiles"
                  @upload-image="handleUploadImage"
                  @change-data="handleChangeData"
                  @type-change="handleChangeType" />
              </div>
            </g-collapse-transition>
          </div>

          <div class="text-primary-325 flex cursor-pointer justify-center">
            <div class="mt-16 flex cursor-pointer select-none items-center gap-12" @click="handleAppendItem">
              <span class="inline-flex">
                <g-base-icon name="circle-plus" viewBox="0 0 512 512" width="20" height="20"></g-base-icon>
              </span>
              Add more option swatch
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </template>
  </g-modal>
</template>
