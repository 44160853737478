import type { ComponentTag } from '@gem/control';
import { defineStore } from 'pinia';
import type { ComponentConfig, ComponentPreset } from '../../common/utils/types';

export type State = {
  loading: {
    builderSettings: boolean;
  };
  builderSettings: Record<string, ComponentConfig>; // map by tag
  componentPresets: Record<string, ComponentPreset>; // map by id
};

export const useBuilderConfigStore = defineStore('builder-config', {
  state: (): State => ({
    loading: {
      builderSettings: true,
    },
    builderSettings: {},
    componentPresets: {},
  }),
  getters: {
    getLoadingBuilderSettings(state) {
      return state.loading.builderSettings;
    },
    getBuilderSettings(state) {
      return state.builderSettings;
    },
    getBuilderSettingByTag(state) {
      return (tag: ComponentTag) => state.builderSettings[tag];
    },
    getComponentPresets(state) {
      return state.componentPresets;
    },
    getComponentPresetById(state) {
      return (id: string) => state.componentPresets[id];
    },
  },
  actions: {
    setLoadingBuilderSettings(value: boolean) {
      this.loading.builderSettings = value;
    },
    setBuilderSettings(builderSettings: Record<string, ComponentConfig>) {
      this.builderSettings = builderSettings;

      // Set presets to store
      this.componentPresets = {};
      for (const tag in builderSettings) {
        if (Object.prototype.hasOwnProperty.call(builderSettings, tag)) {
          const componentConfig = builderSettings[tag];
          if (componentConfig.presets?.length) {
            componentConfig.presets.forEach((preset) => {
              this.componentPresets[preset.id] = preset;
            });
          }
        }
      }
    },
  },
});

export default useBuilderConfigStore;
