<script setup lang="ts">
import { ref } from 'vue';
import { CornerRadius } from './types';

const emit = defineEmits<{
  (e: 'changeByKey', key: string, value: any): void;
}>();

type Props = {
  value?: CornerRadius;
  disable?: boolean;
};
defineProps<Props>();

const keyActive = ref<string>('');

const borderRadiusInputs = [
  {
    key: 'btlr',
    iconName: 'border-radius-bottom-left',
    iconClass: 'rotate-90',
    iconPosition: 'first',
  },
  {
    key: 'btrr',
    iconName: 'border-radius-bottom-left',
    iconClass: 'rotate-180',
    iconPosition: 'last',
  },
  {
    key: 'bblr',
    iconName: 'border-radius-bottom-left',
    iconPosition: 'first',
  },
  {
    key: 'bbrr',
    iconName: 'border-radius-bottom-left',
    iconClass: '-rotate-90',
    iconPosition: 'last',
  },
];

const onChangeInput = (value?: string) => {
  if (keyActive.value) emit('changeByKey', keyActive.value, value || '0');
};
</script>

<template>
  <g-popover
    :closeable="true"
    class="aspect-square h-full"
    :overlay="false"
    arrow-class="!text-dark-400 translate-x-[-42px]"
    overlay-container="#root-modal"
    placement="bottom-end"
    wrapper-class="translate-x-[42px]"
    cls="!p-0">
    <template #default="{ open }">
      <g-button type="moreSetting" size="medium" :active="open" :only-icon="true" class="h-full">
        <g-base-icon name="more-setting-20" width="20" height="20" view-box="0 0 20 20"></g-base-icon>
      </g-button>
    </template>
    <template #content="{ close }">
      <div ref="popup" class="bg-dark-400 rounded-medium w-screen max-w-[216px] transform overflow-hidden px-4 sm:px-0">
        <div class="text-dark-high text-14 flex h-[56px] items-center justify-between px-16">
          <span>Corner</span>
          <g-button type="ghost" size="small" :only-icon="true" @click="close">
            <g-base-icon class="cursor-pointer" name="close-more-setting" width="16" height="16" view-box="0 0 16 16" />
          </g-button>
        </div>
        <div class="flex flex-col p-16 pt-0">
          <!-- <RadiusCore :value="value" @change="onChangeInput" @on-change="onChange" /> -->
          <div class="grid grid-cols-2 gap-8">
            <g-input
              v-for="(item, index) in borderRadiusInputs"
              :key="index"
              :value="parseInt((value?.[item.key as keyof CornerRadius] as string) ?? '0')"
              type="number"
              input-style="secondary"
              :icon-position="item.iconPosition"
              classes="gemx-hidden-arrow text-center bg-dark-300 border-dark-300"
              @on-change="onChangeInput"
              @focus="keyActive = item.key"
              @blur="keyActive = ''">
              <template #icon>
                <g-base-icon
                  class="text-dark-150"
                  :class="item.iconClass"
                  :name="(item.iconName as any)"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20" />
              </template>
            </g-input>
          </div>
        </div>
      </div>
    </template>
  </g-popover>
</template>
