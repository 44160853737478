<template>
  <button
    type="button"
    role="button"
    aria-pressed="false"
    class="gt_text_format_ordered-list item"
    @click="setFormat('.gt_text_format_ordered-list')">
    <svg class="fr-svg" focusable="false" width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5,16h2v.5h-1v1h1V18h-2v1h3V15h-3Zm1-7h1V5h-2V6h1Zm-1,2H4.3L2.5,13.1V14h3V13H3.7l1.8-2.1V10h-3Zm5-5V8h14V6Zm0,12h14V16H7.5Zm0-5h14V11H7.5Z" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'FormatOrderedList',
  components: {},
  props: {
    types: [Array, Object],
  },
  emits: ['statusFormatText'],
  methods: {
    setFormat(classButton) {
      const $parrantButton = event.target.closest(classButton);
      this.$emit('statusFormatText', {
        type: 'insertOrderedList',
        classButton: $parrantButton,
      });
    },
  },
};
</script>
