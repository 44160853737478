import type * as Types from '@/types/graphql';

export type ThemeStyleSelectFragment = Pick<
  Types.ThemeStyle,
  'createdAt' | 'data' | 'default' | 'id' | 'name' | 'updatedAt'
>;

export const ThemeStyleSelectFragmentDoc = `
    fragment ThemeStyleSelect on ThemeStyle {
  createdAt
  data
  default
  id
  name
  updatedAt
}
    `;
