<template>
  <button
    type="button"
    role="button"
    aria-pressed="false"
    class="gt_text_format_center item"
    @click="setFormat('.gt_text_format_center')">
    <svg class="fr-svg" focusable="false" width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9,18h6v-2H9V18z M6,11v2h12v-2H6z M3,6v2h18V6H3z" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'FormatTextCenter',
  emits: ['statusFormatText'],
  methods: {
    setFormat(classButton) {
      const $parrantButton = event.target.closest(classButton);
      this.$emit('statusFormatText', {
        type: 'justifyCenter',
        classButton: $parrantButton,
      });
    },
  },
};
</script>
