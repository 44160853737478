<script lang="ts" setup>
import ColorPicker from '../../color-picker/ColorPicker.vue';

const emit = defineEmits<{
  (e: 'deleteColor', index: number, position: number): void;
  (e: 'changeColor', index: number, position: number, value?: string): void;
}>();

const props = defineProps<{
  index: number;
  position: number;
  isColor?: boolean;
  color?: string;
}>();

const handleChangeColor = (value: string) => {
  emit('changeColor', props.index, props.position, value);
};
</script>

<template>
  <g-popover v-if="isColor" :closeable="true" :has-arrow="false" placement="bottom">
    <div class="h-32 w-32 outline-none">
      <div v-if="color" class="relative h-full w-full cursor-pointer" :style="{ 'background-color': color }">
        <!-- <g-base-icon
          name="circle-close"
          class="absolute top-[-6px] right-[-6px] text-red-500"
          viewBox="0 0 512 512"
          width="12"
          height="12"
          @click.stop="handleDeleteColor"></g-base-icon> -->
      </div>
      <div
        v-else
        class="border-light-300 flex h-full w-full cursor-pointer items-center justify-center border border-solid">
        <span class="text-light-200"><g-base-icon name="plus-bold" width="16" height="16"></g-base-icon></span>
      </div>
    </div>
    <template #content="{}">
      <div
        :style="{
          transform: 'translateX(-100%)',
        }"
        class="absolute z-[999] bg-[#FDFDFD] p-12 text-black">
        <ColorPicker
          :value="color ?? '#ffffff'"
          hex-class="text-[#212121]"
          alpha-class="text-[#676767]"
          colorSvgEyeDropper="#212121"
          bgHoverSvgEyeDropper="hover:bg-[#e4e4e4]"
          @change-color="handleChangeColor" />
      </div>
    </template>
  </g-popover>
  <div v-else class="h-32 w-32">
    <div
      class="border-light-400 flex h-full w-full cursor-not-allowed items-center justify-center border border-solid opacity-30">
      <span class="text-light-200"><g-base-icon name="plus-bold" width="16" height="16"></g-base-icon></span>
    </div>
  </div>
</template>
