<script lang="ts" setup>
const props = defineProps<{
  controlId: string;
  value?: string;
}>();

const emit = defineEmits<{
  controlOnChange: [controlId: string, value?: string];
  controlChange: [controlId: string, value?: string];
}>();

const controlOnChange = (evt: Event) => {
  emit('controlOnChange', props.controlId, (evt.target as HTMLInputElement).value);
};

const controlChange = (evt: Event) => {
  emit('controlChange', props.controlId, (evt.target as HTMLInputElement).value);
};
</script>

<template>
  <input
    :value="value"
    class="caret-primary-300 text-12 placeholder:text-dark-disabled focus:!border-primary-300 focus:!bg-dark-400 group-hover:border-dark-200 group-hover:bg-dark-200 disabled:border-dark-200 disabled:bg-dark-200 text-dark-high bg-dark-400 border-dark-400 rounded-medium undefined mr-[-1px] h-[36px] w-full border px-8 outline-none transition-colors duration-200 disabled:cursor-not-allowed"
    @change="controlChange"
    @input="controlOnChange" />
</template>
