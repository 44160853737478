<script setup lang="ts">
import { ref, watch } from 'vue';
import RadiusMoreSetting from './RadiusMoreSetting.vue';
import type { CornerRadius } from './types';

import NumberUnit from '../input/NumberUnit.vue';

type Props = {
  id: string;
  value?: CornerRadius;
  mobileOnly?: boolean;
  presetValue?: {
    large: string | undefined;
    medium: string | undefined;
    small: string | undefined;
  };
};

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value: any): void;
}>();

const props = defineProps<Props>();

const isCustom = (borderRadiusData?: CornerRadius) => {
  const bblr = borderRadiusData?.bblr || '';
  const bbrr = borderRadiusData?.bbrr || '';
  const btlr = borderRadiusData?.btlr || '';
  const btrr = borderRadiusData?.btrr || '';
  return !(bblr === bbrr && bblr === btrr && bblr === btlr);
};

const getInitInputValue = () => {
  if (!isCustom(props.value)) {
    return props.value?.bblr || '0px';
  } else {
    return 'Custom';
  }
};

const inputValue = ref(getInitInputValue());
const val = ref<CornerRadius>(props.value ?? {});
const disable = ref(false);

const updateBorderRadius = (value: string) => {
  if (value == undefined) {
    return;
  }
  val.value.bblr = value || '0px';
  val.value.bbrr = value || '0px';
  val.value.btlr = value || '0px';
  val.value.btrr = value || '0px';
  inputValue.value = value;
  change();
};

const updateBorderRadiusByKey = (name: string, value: string) => {
  val.value = { ...props.value, [name]: parseInt(value) + 'px' };
  if (isCustom(val.value)) inputValue.value = 'Custom';
  else inputValue.value = val.value?.bblr || '0';
  change();
};

const change = () => {
  val.value.radiusType = 'custom';
  emit('controlChange', props.id, val.value);
};

watch(
  () => props.value,
  (newVal) => {
    if (newVal) {
      val.value = newVal;
    }
  },
);
</script>

<template>
  <div class="w-full">
    <div class="flex h-36 w-full items-center gap-8">
      <NumberUnit
        :value="inputValue"
        :min="0"
        :convert-null-to-min="true"
        :units="['px']"
        :hide-unit="false"
        placeholder="Auto"
        @change="updateBorderRadius"
        @on-change="updateBorderRadius" />
      <RadiusMoreSetting :value="value" :disable="disable" @change-by-key="updateBorderRadiusByKey" />
    </div>
  </div>
</template>
