<template>
  <div class="gemx-control gt_control-position">
    <slot name="label"></slot>
    <div class="gt_control">
      <div class="gt_control-position-bound">
        <div class="gt_control-position-icon">
          <div class="gt_control-position-top">
            <button @click="changeValue('0', '0')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <path d="M15 22V15H22V17H17V22H15Z" fill="#E2E2E2" />
              </svg>
            </button>
            <button @click="changeValue('0', '50%')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <rect x="9" y="15" width="14" height="2" fill="#E2E2E2" />
              </svg>
            </button>
            <button @click="changeValue('0', '100%')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <path d="M17 22V15H10V17H15V22H17Z" fill="#E2E2E2" />
              </svg>
            </button>
          </div>
          <div class="gt_control-position-middle">
            <button @click="changeValue('50%', '0')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <rect x="17" y="9" width="14" height="2" transform="rotate(90 17 9)" fill="#E2E2E2" />
              </svg>
            </button>
            <button @click="changeValue('50%', '50%')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <circle cx="16" cy="16" r="3" fill="#E2E2E2" />
              </svg>
            </button>
            <button @click="changeValue('50%', '100%')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <rect x="15" y="23" width="14" height="2" transform="rotate(-90 15 23)" fill="#E2E2E2" />
              </svg>
            </button>
          </div>
          <div class="gt_control-position-bottom">
            <button @click="changeValue('100%', '0')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <path d="M15 10V17H22V15H17V10H15Z" fill="#E2E2E2" />
              </svg>
            </button>
            <button @click="changeValue('100%', '50%')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <rect x="9" y="15" width="14" height="2" fill="#E2E2E2" />
              </svg>
            </button>
            <button @click="changeValue('100%', '100%')">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="#424242" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" fill="currentColor" stroke="currentColor" />
                <path d="M17 10V17H10V15H15V10H17Z" fill="#E2E2E2" />
              </svg>
            </button>
          </div>
        </div>
        <div class="gt_control-position-custom">
          <div class="gt_control-positionX">
            <span>X-pos</span>
            <input
              v-model="val.x"
              type="text"
              placeholder="0"
              @keyup.stop="onChange"
              @change.stop="change"
              @focus.stop="focus"
              @blur.stop="blur" />
          </div>
          <div class="gt_control-positionY">
            <span>Y-pos</span>
            <input
              v-model="val.y"
              type="text"
              placeholder="0"
              @keyup.stop="onChange"
              @change.stop="change"
              @focus.stop="focus"
              @blur.stop="blur" />
          </div>
        </div>
      </div>
    </div>
    <slot name="info"></slot>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';

type PropsType = {
  id?: string | number;
  value?: any;
};
const props = withDefaults(defineProps<PropsType>(), {
  id: '',
  value: {},
});

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

const val = ref({ x: props.value.x, y: props.value.y });
watch(props.value, (value) => {
  setValue(value);
});
// val.value = props.value;

const setValue = (value: any) => {
  if (value != val.value) {
    val.value = value;
  }
};
const changeValue = (top: any, left: any) => {
  val.value.x = top;
  val.value.y = left;
  onChange();
};

const onChange = () => {
  emit('controlOnChange', props.id, val.value);
};

const change = () => {
  emit('controlChange', props.id, val.value);
};
const focus = () => {
  emit('controlFocus', props.id, val.value);
};
const blur = () => {
  emit('controlBlur', props.id, val.value);
};
</script>

<style lang="scss" scoped>
.gt_control {
  position: relative;
  .gt_control-position-bound {
    display: flex;
  }

  .gt_control-position-icon {
    margin-right: 16px;
  }
  .gt_control-position-top {
    display: flex;
    color: #424242;
    svg {
      fill: currentColor;
      path {
        &:active {
          fill: #242424 !important;
        }
      }
      rect {
        &:active {
          fill: #242424 !important;
        }
      }
    }
  }
  .gt_control-position-middle {
    display: flex;
    color: #424242;
    svg {
      fill: currentColor;
      path {
        &:active {
          fill: #242424 !important;
        }
      }
      rect {
        &:active {
          fill: #242424 !important;
        }
      }
    }
  }
  .gt_control-position-bottom {
    display: flex;
    color: #424242;
    svg {
      fill: currentColor;
      path {
        &:active {
          fill: #242424 !important;
        }
      }
      rect {
        &:active {
          fill: #242424 !important;
        }
      }
    }
  }
  .gt_control-positionX {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    span {
      font-size: 14px;
      margin-right: 5px;
      white-space: nowrap;
    }
    input {
      max-width: 100%;
      width: 60px;
      height: 32px;
    }
  }

  .gt_control-positionY {
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      margin-right: 5px;
      white-space: nowrap;
    }
    input {
      max-width: 100%;
      width: 60px;
      height: 32px;
    }
  }
}
</style>
