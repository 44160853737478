<template>
  <button
    type="button"
    role="button"
    aria-pressed="false"
    class="gt_text_format_unorder-list item"
    @click="setFormat('.gt_text_format_unorder-list')">
    <svg class="fr-svg" focusable="false" width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4,10.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S4.8,10.5,4,10.5z M4,5.5C3.2,5.5,2.5,6.2,2.5,7  S3.2,8.5,4,8.5S5.5,7.8,5.5,7S4.8,5.5,4,5.5z M4,15.5c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5S4.8,15.5,4,15.5z   M7.5,6v2h14V6H7.5z M7.5,18h14v-2h-14V18z M7.5,13h14v-2h-14V13z" />
    </svg>
  </button>
</template>
<script>
export default {
  name: 'FormatUnorderList',
  props: {
    types: [Array, Object],
  },
  emits: ['statusFormatText'],
  methods: {
    setFormat(classButton) {
      const $parrantButton = event.target.closest(classButton);
      this.$emit('statusFormatText', {
        type: 'insertUnorderedList',
        classButton: $parrantButton,
      });
    },
  },
};
</script>
