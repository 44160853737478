<script lang="ts" setup>
import { reactive, ref } from 'vue';
import 'vue-color-kit/dist/vue-color-kit.css';

type Props = {
  id: string;
  value?: string;
};
defineProps<Props>();

const emit = defineEmits<{
  (e: 'controlOnChange', controlId?: string | number, value?: any): void;
  (e: 'controlChange', controlId?: string | number, value?: any): void;
  (e: 'controlFocus', controlId?: string | number, value?: any): void;
  (e: 'controlBlur', controlId?: string | number, value?: any): void;
}>();

type RadiusUnit = {
  value: number;
  unit: string;
};

type Radius = {
  radius: RadiusUnit;
  btlr: RadiusUnit;
  btrr: RadiusUnit;
  bblr: RadiusUnit;
  bbrr: RadiusUnit;
};
const cornerRadius = reactive<Radius>({
  radius: {
    value: 0,
    unit: 'px',
  },
  btlr: {
    value: 0,
    unit: 'px',
  },
  btrr: {
    value: 0,
    unit: 'px',
  },
  bblr: {
    value: 0,
    unit: 'px',
  },
  bbrr: {
    value: 0,
    unit: 'px',
  },
});

const radiusKey = ref<keyof typeof cornerRadius>('radius');

const change = () => {
  emit('controlChange', radiusKey.value, `${cornerRadius[radiusKey.value].value}${cornerRadius[radiusKey.value].unit}`);
};
const onChange = () => {
  emit(
    'controlOnChange',
    radiusKey.value,
    `${cornerRadius[radiusKey.value].value}${cornerRadius[radiusKey.value].unit}`,
  );
};
</script>
<template>
  <div class="gemx-control--radius flex py-8">
    <div class="grid grid-cols-3 gap-8 px-8">
      <div
        class="gemx-control--radius__side"
        :class="{ ' bg-black': radiusKey === 'btlr' }"
        @click="radiusKey = 'btlr'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 64 64"
          height="1em"
          width="1em"
          class="custom-svg-icon"
          style="fill: currentcolor; width: 16px; height: 16px">
          <path
            d="M32 16H16v16a4 4 0 01-8 0V15a7 7 0 017-7h17a4 4 0 010 8zm12-2h5a1 1 0 011 1v34a1 1 0 01-1 1H15a1 1 0 01-1-1v-5h-4v5a5 5 0 005 5h34a5 5 0 005-5V15a5 5 0 00-5-5h-5v4z"
            fill="currentColor"></path>
        </svg>
      </div>
      <div class=""></div>
      <div
        class="gemx-control--radius__side"
        :class="{ ' bg-black': radiusKey === 'btrr' }"
        @click="radiusKey = 'btrr'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 64 64"
          height="1em"
          width="1em"
          class="custom-svg-icon"
          style="fill: currentcolor; width: 16px; height: 16px; transform: rotate(90deg)">
          <path
            d="M32 16H16v16a4 4 0 01-8 0V15a7 7 0 017-7h17a4 4 0 010 8zm12-2h5a1 1 0 011 1v34a1 1 0 01-1 1H15a1 1 0 01-1-1v-5h-4v5a5 5 0 005 5h34a5 5 0 005-5V15a5 5 0 00-5-5h-5v4z"
            fill="currentColor"></path>
        </svg>
      </div>
      <div class=""></div>

      <div
        class="gemx-control--radius__side"
        :class="{ ' bg-black': radiusKey === 'radius' }"
        @click="radiusKey = 'radius'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 64 64"
          height="1em"
          width="1em"
          class="custom-svg-icon"
          style="fill: currentcolor; width: 16px; height: 16px">
          <path
            d="M49 56H36v-8h12V36h8v13a7 7 0 01-7 7zm7-28V15a7 7 0 00-7-7H36v8h12v12h8zM16 16h12V8H15a7 7 0 00-7 7v13h8V16zm0 20H8v13a7 7 0 007 7h13v-8H16V36z"
            fill="currentColor"></path>
        </svg>
      </div>
      <div class=""></div>

      <div
        class="gemx-control--radius__side"
        :class="{ ' bg-black': radiusKey === 'bblr' }"
        @click="radiusKey = 'bblr'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 64 64"
          height="1em"
          width="1em"
          class="custom-svg-icon"
          style="fill: currentcolor; width: 16px; height: 16px; transform: rotate(-90deg)">
          <path
            d="M32 16H16v16a4 4 0 01-8 0V15a7 7 0 017-7h17a4 4 0 010 8zm12-2h5a1 1 0 011 1v34a1 1 0 01-1 1H15a1 1 0 01-1-1v-5h-4v5a5 5 0 005 5h34a5 5 0 005-5V15a5 5 0 00-5-5h-5v4z"
            fill="currentColor"></path>
        </svg>
      </div>
      <div class=""></div>
      <div
        class="gemx-control--radius__side"
        :class="{ ' bg-black': radiusKey === 'bbrr' }"
        @click="radiusKey = 'bbrr'">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 64 64"
          height="1em"
          width="1em"
          class="custom-svg-icon"
          style="fill: currentcolor; width: 16px; height: 16px; transform: rotate(180deg)">
          <path
            d="M32 16H16v16a4 4 0 01-8 0V15a7 7 0 017-7h17a4 4 0 010 8zm12-2h5a1 1 0 011 1v34a1 1 0 01-1 1H15a1 1 0 01-1-1v-5h-4v5a5 5 0 005 5h34a5 5 0 005-5V15a5 5 0 00-5-5h-5v4z"
            fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <div class="flex flex-col justify-center px-8">
      <div class="mt-4 flex flex-col items-start">
        <p class="">Radius</p>
        <div class="flex w-full">
          <input
            v-model="cornerRadius[radiusKey].value"
            type="number"
            class="w-full pl-12 text-slate-900 outline-none"
            placeholder="radius"
            @change="change()"
            @onChange="onChange()" />
          <select v-model="cornerRadius[radiusKey].unit" class="text-slate-900 outline-none" @change="change()">
            <option value="px">px</option>
            <option value="em">em</option>
            <option value="rem">rem</option>
            <option value="vh">vh</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.gemx-control--radius {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #e2e2e2;
  background-color: #424242;
  width: 250px;
  .gemx-control--radius__side {
    & .active {
      background-color: black;
    }
  }
}
</style>
